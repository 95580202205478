/*------------------------------------*\
    #PRODUCT-FILTER-UNDERLYING
\*------------------------------------*/

product-filter-underlying {
    .list {
        .list-wrapper {
            overflow-y: auto;
            height: calc(100vh - 310px);
            ul {
                margin: 5px 0 30px 0;
                padding: 0 $filterPadding;
                width: 100%;
                li {
                    padding: 10px 0;
                    &.label {
                        background: none;
                        em {
                            font-style: normal;
                            border-bottom: 2px solid $backgroundColorNotSoDark;
                            display: block;
                            padding: 0 0 8px 0;
                            margin: 0 0 3px 0;
                            &.active {
                                border-bottom-color: $highlightColor;
                            }
                        }
                        ul {
                            padding: 0;
                            margin: 0;
                            li {
                                border-bottom: 1px solid $backgroundColorNotSoDark;
                                font-family: $mainFont;
                                padding: 10px 0 6px 0;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

.ios product-filter-underlying .list .list-wrapper>ul:last-child {
    margin-bottom: 100px;
}

/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    product-filter-underlying {
        .list {
            .list-wrapper {
                ul {
                    padding: 0 $borderDimTablet;
                }
            }
        }
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    product-filter-underlying {
        .list {
            &:before {
                left: calc(25% + 5px);
            }
            .list-wrapper {
                height: 326px;
                margin: 0;
                ul {
                    padding: 0 $filterPadding;
                    li {
                        &.label {
                            ul {
                                display: flex;
                                flex-wrap: wrap;
                                li {
                                    width: calc(100% / 4 - 15px);
                                    margin: 0 0 0 20px;
                                    &:nth-child(4n - 3) {
                                        margin-left: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}