columns {
    display: block;
    padding: 0 $borderDimMobile;

    .custom-column {
        .column-content {

            p, p span {
                font-size: $fontSizeMiddle;
                line-height: 25px;
            }

            a, p a, p span a {
                display: block;
                color: $highlightColor;
                font-size: $fontSizeMiddle;
                font-family: $mainFontBold;
                position: relative;
                padding-left: 25px;

                &:hover {
                    text-decoration: none;
                }
                &:before {
                    position: absolute;
                    font-family: $iconFont;
                    content: '\e902';
                    margin-left: -25px;
                    font-size: $fontSizeTiny;
                }
            }
            p > a, p span > a {
                padding-top: 0;
            }
            a + a, p a + a, p span a + a {
                padding-top: 0;
                line-height: 40px;
            }

            .links, p.links {
                margin-top: 15px;
                a {
                    padding-top: 0;
                    line-height: 40px;
                }
            }

        }

        &:first-child {
            .column-content {
                padding-left: 0;
            }
        }

        &:last-child {
            .column-content {
                padding-right: 0;
            }
        }
    }

    ul,
    ol {
        margin: 18px 0 0 0;
        padding: 0 0 0 17px;

        li {
            font-family: $mainFontLight;
            font-size: $fontSizeRegular;
            line-height: 24px;
            padding-left: 7px;
            padding-bottom: 11px;
        }
    }

    ul {
        list-style-type: disc;
        padding: 0 0 0 15px;
        li {
            padding-left: 0px;
        }
        &.list-dash {
            padding-left: 0;
            list-style-type: none;

            li:before {
                content: "-";
                display: inline-block;
                margin-right: 12px;
            }
        }
    }

    ol {
        list-style-type: decimal;
    }
}
@include mediaQueryMedium {
    columns {
        padding: 0 $borderDimTablet;
    }
}
/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    columns {
        padding: 0 $borderDimDesktop;

        section {
            padding: 0 6.3vw;
        }
        .custom-column {
            .column-content {
                padding-top: 0;
                padding-left: 50px;
                padding-right: 50px;
            }
        }
        .custom-column + .custom-column {
            .column-content {
                padding-top: 0;
            }
        }

        ul,
        ol {
            li {
                font-size: $fontSizeMiddle;
            }
        }
    }
}
