/*------------------------------------*\
    #PRODUCT-FILTER-CHECKBOX-MULTIPLE
\*------------------------------------*/

product-filter-checkbox-multiple {
    .checkbox-field {
        margin: 30px 0 0 0;
        width: 100%;
        
        fieldset {
            display: block;
            
            legend {
                display: block;
                margin-bottom: 20px;
                line-height: 22px;
                font-family: $mainFontBold;
                font-size: $fontSizeMiddle;
                color: $textColorDark;
            }
            
            ul {
                li + li {
                    margin-top: 25px;
                }
            }
        }
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    product-filter-checkbox-multiple {}
}


/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    product-filter-checkbox-multiple {}
}