/*------------------------------------*\
    #CONTACT
\*------------------------------------*/

contact {
    &.icon-loading {
        &:before {
            position: fixed;
            top: 50px;
            left: 0;
            margin: 0;
            width: 100%;
            height: calc(100vh - 50px);
            border-radius: 0;
            background: $backgroundColorBright;
        }

        &:after {
            position: fixed;
            top: 150px;
        }
    }


    /**
     * Contact tabs.
     */
    tabs {
        nav {
            ul {
                li {
                    flex-grow: 1;

                    a {
                        font-size: $fontSizeRegular;
                    }
                }

                li + li {
                    margin-left: 2px;
                }
            }
        }

        tab {
            &:before {
                display: none;
            }

            section {
                padding: 30px $borderDimMobile 0 $borderDimMobile;
            }
        }
    }


    /**
     * Contact form.
     */
    .contact-form {
        position: relative;
        padding-bottom: 30px;

        &.icon-loading:before {
            top: 0;
            left: 0;
            margin: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 0;
        }

        h3 {
            display: none;
        }

        fieldset {
            &.textarea-group {
                margin-top: 40px;
            }

            &.checkbox-group {
                margin-top: 40px;
            }

            &.button-group {
                margin-top: 30px;
            }
        }
    }

    .description {
        line-height: 24px;
        font-size: 16px;
    }


    /**
     * Contact person.
     */
    .contact-person {
        margin-top: 30px;

        li {
            display: flex;
            margin-bottom: 30px;
        }

        img {
            margin-right: 20px;
            min-width: 127px;
            height: 127px;
            border-radius: 80px;
        }

        &-column {
            display: flex;
            flex-wrap: wrap;

            strong,
            span,
            a {
                width: 100%;
            }

            strong {
                line-height: 21px;
                color: $textColorNotSoDark;
            }

            span {
                line-height: 24px;
                color: $textColorNotSoBlack;
            }

            a {
                display: flex;
                align-items: center;
                margin-top: 15px;
                line-height: 24px;
                color: $textColorNotSoBlack;

                &:hover {
                    text-decoration: none;
                }

                i {
                    margin-right: 10px;

                    &:before {
                        width: 12px;
                        height: 12px;
                        line-height: 12px;
                        font-size: 12px;
                        color: $textColorNotSoBlack;
                    }
                }
            }

            a + a {
                margin-top: 0;
            }
        }
    }

    .phone-disclaimer {
        padding: 0 0 20px 0;
        color: $textColorMain;
        font-size: $fontSizeVeryTiny;
        line-height: 14px;
        font-family: $mainFontLight;

    }
}


/*------------------------------------*\
    #MEDIA-QUERY-MOBILE
\*------------------------------------*/

@include mediaQueryMobile {
    contact .contact-person-column {
        font-size: $fontSizeRegular;
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    contact {
        &.icon-loading {
            &:before {
                top: 160px;
                height: calc(100vh - 160px);
            }

            &:after {
                top: 260px;
            }
        }

        h3 {
            margin: 0 0 25px 0;
            line-height: 25px;
            font-size: $fontSizeBig;
        }

        .description {
            line-height: 25px;
            font-size: 18px;
        }

        .contact-cards {
            margin: 0 auto;
            width: 1280px;
        }


        /**
         * Contact person.
         */
        .contact-person {
            display: flex;
            flex-wrap: wrap;
            margin-top: 40px;

            li {
                max-width: 33%;
                min-width: 425px;
            }

            img {
                min-width: 154px;
                height: 154px;
            }

            &-column {
                strong,
                span,
                a {
                    font-size: 18px;
                }

                strong {

                }

                span {
                    line-height: 25px;
                }
            }
        }

        separator {
            hr {
                margin-top: 50px;
                margin-bottom: 60px;
            }
        }

        /**
         * Contact form.
         */
        .contact-form {
            margin: 10px auto 0 auto;
            width: 50%;

            h3 {
                display: block;
            }
        }

        .phone-disclaimer {
            font-size: $fontSizeNotSoTiny;
            line-height: 18px;
            font-family: $mainFont;
        }
    }
}