/*------------------------------------*\
    #LOCALE-SELECTOR
\*------------------------------------*/

.show-menu locale-selector {
    display: block;
}

locale-selector {
    position: fixed;
    top: calc(100vh);
    right: 0;
    width: calc(100vw - 50px);
    box-sizing: border-box;
    z-index: 21;
    display: none;
    .locale-selector {
        border-top: 1px solid #9B9B9B;
        display: flex;
        flex-wrap: wrap;
        font-size: $fontSizeMiddle;
        &-entry {
            font-size: 18px;
            line-height: 50px;
            height: 50px;
            &:after {
                content: "|";
                padding: 0 6px 0 5px;
            }
            &:nth-child(2) {
                padding-left: $borderDimMobile;
            }
            &:last-child:after {
                content: "";
            }
            &.active {
                font-family: $mainFontBold;
            }
            &.additional {
                display: none;
                padding-right: 10px;
                &:after {
                    display: none;
                }
            }
        }
    }
}


/*------------------------------------*\
  #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    locale-selector {
        width: 50%;
    }
}


/*------------------------------------*\
  #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    .sticky {
        locale-selector {
            top: 0;
        }
    }
    locale-selector {
        display: block;
        top: 0;
        padding: 0;
        width: auto;
        right: 255px;
        position: absolute;
        .locale-selector {
            position: absolute;
            border-top: none;
            width: 240px;
            &-entry {
                font-size: $fontSizeRegular;
                line-height: $mainHeaderSizeRegular;
                height: $mainHeaderSizeRegular;
                &.additional {
                    display: block;
                }
            }
        }
    }
}