/*------------------------------------*\
    #LIGHTBOX
\*------------------------------------*/

body.show-lightbox {
    overflow: hidden;
}

lightbox {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background: $backgroundColorWhite;
    
    header {
        position: relative;
        height: 50px;
        text-align: center;
        background: $headerColor;
        
        .title {
            display: inline-block;
            line-height: 50px;
            color: $textColorWhite;
        }
        
        h2.title {
            margin-top: 0;
            padding-top: 0;
            font-size: 28px;
        }
        
        .icon-close {
            position: absolute;
            top: 15px;
            right: 30px;
            cursor: pointer;
            
            &:before {
                width: 20px;
                height: 20px;
                line-height: 20px;
                color: $textColorWhite;
                font-size: 20px;
            }
        }
    }
    
    > section {
        box-sizing: border-box;
        overflow-y: auto;
        padding: 30px 0 0 0;
        height: calc(100vh - 50px);
    }
}

/* For iOS to avoid Safari bottom toolbar */
@media screen and (-webkit-min-device-pixel-ratio:0) {
    lightbox {

        overflow: scroll;
        -webkit-overflow-scrolling: touch;

        > section {
            padding-bottom: 70px;
        }
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {}


/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    lightbox {
        header {
            height: 160px;

            .title {
                line-height: 160px;
                font-size: $fontSizeTall;
                padding: 0;
            }

            h2.title {
                font-size: $fontSizeTall;
            }

            .icon-close {
                top: 40px;
                right: 57px;

                &:before {
                    width: 33px;
                    height: 33px;
                    line-height: 33px;
                    font-size: 33px;
                }
            }
        }
        
        > section {
            padding: 60px $borderDimDesktop $borderDimDesktop $borderDimDesktop;
            height: calc(100vh - 160px);
        }
    }
}