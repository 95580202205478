investment-idea-detail-component {
  > section {
    .investment-idea-detail-container {
      height: 250px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .header {
      margin: -125px auto 0;
      background-color: #ffffff;
      padding-top: 28px;
      max-width: 350px;

      h3 {
        text-align: center;
      }
    }

    .content {
      margin: 74px auto 0;
      max-width: 780px;
      padding: 0 12px;

      .labels {
        margin-bottom: 20px;

        ul li {
          display: inline-block;
          background-color: #F5F6F8;
          padding: 4px 10px 3px;
          margin-right: 10px;
          font-size: 16px;
          border-radius: 4px;
          margin-bottom: 12px;
        }
      }
    }
  }
}

@include mediaQueryRegular {
  investment-idea-detail-component {
    > section {
      .investment-idea-detail-container {
        height: 380px;
      }
      .header {
        max-width: 700px;
      }
    }
  }
}