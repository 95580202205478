/*------------------------------------*\
    #TABS
\*------------------------------------*/

tabs {
    nav {
        overflow-x: auto;
        width: 100%;
        ul {
            display: flex;
            justify-content: center;
            width: -moz-max-content;

            li {
                display: inline-block;
                flex-grow: 1;
                position: relative;
                cursor: pointer;
                overflow: hidden;
                
                div {
                    display: table;
                    width: 100%;
                    height: 100%;
                }

                a {
                    box-sizing: border-box;
                    display: table-cell;
                    height: 50px;
                    line-height: 18px;
                    vertical-align: middle;
                    font-size: $fontSizeRegular;
                    text-align: center;
                    background: #f5f6f8;
                    border-top: 4px solid #f5f6f8;
                    border-right: 1px solid #f5f6f8;
                    border-left: 1px solid #f5f6f8;
                    color: $linkColor2;
                    .text-width {
                        display:block;
                        font-family: $mainFontBold;
                        padding:0 1px;
                        visibility: hidden;
                        line-height: 0;
                    }
                    &:hover {
                        text-decoration: none;
                    }
                }
                &.active {
                    cursor: default;
                }
                &.active a {
                    font-family: $mainFontBold;
                    background: #FFF;
                    border-top-color: #007DB2;
                    color: $linkColor2;
                }
                &.active:after {
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: -5px;
                    left: 0;
                    width: 100%;
                    height: 5px;
                    background: $backgroundColorWhite;
                }
            }
            li+li {
                margin-left: 2px;
            }
        }
    }
    tab {
       /* display: none; */
        &:before {
            content: "";
            display: block;
            height: $tabDimMobile;
        }
        /**
         * Clear padding for sections within tabs.
         */
        tabs {
            tab {
                section {
                    padding: 0 !important;
                }
            }
        }
    }

    &.tabs-product-list>tab {
        display: block;
        margin-top: $tabDimMobile;
        &:before {
            content: none;
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    tabs {
        nav {
            ul {
                li {
                    a {
                        padding: 0 30px;
                    }
                }
            }
        }

        tab:before {
            height: $tabDimTablet;
        }

        &.tabs-product-list>tab {
            display: block;
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    tabs {
        nav {
            ul {
                display: flex;
                width: 100%;
                border-bottom: 1px solid #F5F6F8;
                li {
                    display: block;
                    flex-grow: 0;
                    &.active {
                        -webkit-box-shadow: 0 4px #fff, 0 1px 3px 0 rgba(0,0,0,.25);
                        box-shadow: 0 4px #fff, 0 1px 3px 0 rgba(0,0,0,.25);
                    }
                    a {
                        font-size: $fontSizeMiddle;
                    }
                    &:hover:not(.active) {
                        -webkit-box-shadow: 0 2px #f5f6f8, 0 1px 3px 0 rgba(0,0,0,.25);
                        box-shadow: 0 2px #f5f6f8, 0 1px 3px 0 rgba(0,0,0,.25);
                    }
                }

                li+li {
                    margin-left: 5px;
                }
            }
        }
        
        tab:before {
            height: $tabDimDesktop;
        }
        
        &.tabs-product-list>tab {
            display: block;
            margin-top: 0;
        }
    }
}