person-quote-component {
	> section {

		background: #f5f6f8;
		margin-top: 100px!important;
		overflow: unset!important;
		padding-top: 50px!important;

		.quote-container {
			position: relative;
			.image-circle {
				width: 100px;
				height: 100px;
				border-radius: 50px;
				background-position: center;
				background-size: 100%;
				background-repeat: no-repeat;
				top: -100px;
				left: calc(50% - 50px);
				position: absolute;
			}
			.text {
				position: relative;
				font-size: 21px;
				line-height: 25px;
				color: #333333;
				max-width: 780px;
				margin: 0 auto;
				font-family: $mainFontLight;
				padding: 20px 54px 70px 54px;
				&:before,
				&:after	{
					font-size: 40px;
					color: #666666;
					position:absolute;
					font-family: $iconFont;
				}
				&:before {
					content: '\e924';
					bottom: 40px;
					left: 3px;
				}
				&:after {
					content: '\e924';
					right: 3px;
					top: 3px;
					transform: rotate(-180deg);
					-webkit-transform: rotate(-180deg);
					-moz-transform: rotate(-180deg);
					-ms-transform: rotate(-180deg);
					-o-transform: rotate(-180deg);
				}
			}
			.author {
				padding-top: 10px;
				font-size: 16px;
				line-height: 24px;
				color: #666666;
			}
		}
	}
}

@include mediaQueryRegular {
	person-quote-component {
		> section {
			.quote-container {

				.text {
					font-size: 34px;
					line-height: 44px;
					padding: 20px 0 70px 0;

					&:before,
					&:after	{
						font-size: 50px;
					}
					&:before {
						bottom: 40px;
						left: -65px;
					}
					&:after {
						right: -50px;
						top: 0;
					}
				}

				.author {
					padding-top: 20px;
					font-size: 18px;
					line-height: 24px;
				}
			}
		}
	}
}