/*------------------------------------*\
    #PRODUCT-FILTER-DROPDOWN-SMALL
\*------------------------------------*/

product-filter-dropdown-small {}

/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    product-filter-dropdown-small {}
}

/*------------------------------------*\
    #MEDIA-QUERY-SLIDER
\*------------------------------------*/

@include mediaQueryRegular {
    product-filter-dropdown-small {
        fieldset {
            display: block;
            .legend {
                margin:20px 0 5px 0 !important;
            }
            button.label {
                height:40px;
                line-height:40px;
                font-size: $fontSizeRegular;
            }
            .list {
                border-radius: 0 0 4px 4px;
                top: 83px;
                position: absolute;
                margin: 0;
                padding: 0 !important;
                width: 100%;
                &:before {
                    width: 100%;
                }
                .list-wrapper {
                    min-height: auto;
                    ul {
                        width: 100%;
                        padding: 0 $filterPadding 20px $filterPadding;
                        li {
                            margin: 0;
                            border-bottom: 1px solid #666;
                            padding: 10px 0 6px 0;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}