/*------------------------------------*\
    #PRODUCT-FILTER-MATURTIY
\*------------------------------------*/

product-filter-maturity {
    .list {
        .calendar {
            display: flex;
            justify-content: space-between;
            margin: 9px $borderDimMobile 20px $borderDimMobile;
            li {
                padding: 0;
                position: relative;
                border-bottom: 1px solid $backgroundColorNotSoDark;
                margin: 0 20px 0 0;
                &:last-child {
                    margin: 0;
                }
                &:after {
                    content: '\e918';
                    font-family: $iconFont;
                    position: absolute;
                    right: 10px;
                    top: 5px;
                    font-size: 14px;
                    cursor: pointer;
                }
                input {
                    box-sizing: border-box;
                    border: none;
                    width: calc(100% - 30px);
                    color: $textColorDark;
                    font-family: $mainFont;
                    font-size: $fontSizeRegular;
                    line-height: 25px;
                    &.ng-invalid {
                        color: $textColorError;
                    }
                }
                datepicker {
                    .align-left {
                        margin-left: -130px;
                    }
                    select {
                        background: none;
                        color: $textColorDark;
                        font-family: $mainFont;
                        font-size: $fontSizeRegular;
                    }
                    ._720kb-datepicker-calendar {
                        box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.25), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
                        min-width: 290px;
                        padding: 5px;
                        margin-top: 10px;
                    }
                    ._720kb-datepicker-default-button {
                        color: transparent;
                        &:before {
                            content: '\e901';
                            font-family: $iconFont;
                            position: absolute;
                            transform: rotate(180deg);
                            left: $borderDimMobile;
                            color: $textColorDark;
                        }
                    }
                    ._720kb-datepicker-calendar-header-right {
                        ._720kb-datepicker-default-button {
                            &:before {
                                transform: none;
                                right: $borderDimMobile;
                                left: auto;
                            }
                        }
                    }
                    ._720kb-datepicker-calendar-header:nth-child(odd),
                    ._720kb-datepicker-calendar-header:nth-child(even) {
                        background: $backgroundColorWhite;
                    }
                    ._720kb-datepicker-calendar-month {
                        color: $textColorDark;
                        font-family: $mainFont;
                        font-size: $fontSizeRegular;
                    }
                    ._720kb-datepicker-calendar-month span {
                        color: $textColorDark;
                        font-family: $mainFont;
                        font-size: $fontSizeRegular;
                        font-weight: normal;
                    }
                    ._720kb-datepicker-calendar-years-pagination a {
                        color: $textColorMain;
                        font-family: $mainFont;
                        font-size: $fontSizeRegular;
                    }
                    ._720kb-datepicker-calendar-years-pagination a:hover {
                        background: $headerColor;
                        color: $textColorWhite;
                    }
                    ._720kb-datepicker-calendar-days-header {
                        background: $backgroundColorWhite;
                    }
                    ._720kb-datepicker-calendar-days-header div {
                        color: $textColorMain;
                        font-family: $mainFont;
                        font-size: $fontSizeRegular;
                    }
                    ._720kb-datepicker-calendar-day {
                        color: $textColorDark;
                        font-family: $mainFont;
                        font-size: $fontSizeRegular;
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        margin: 0 0 3px 0;
                        padding: 0;
                    }
                    ._720kb-datepicker-calendar-day._720kb-datepicker-active,
                    ._720kb-datepicker-calendar-day:hover {
                        background: $highlightColor;
                        border-radius: 100%;
                        color: $textColorWhite;
                    }
                }
            }
        }
        .list-wrapper {
            ul {
                margin: 5px 0 30px 0;
                padding: 0 $borderDimMobile;
                width: 100%;
                li {
                    border-bottom: 1px solid $backgroundColorNotSoDark;
                    padding: 10px 0 6px 0;
                }
            }
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    product-filter-maturity {
        .list {
            .calendar {
                margin: 9px $borderDimTablet 20px $borderDimTablet;
            }
            .list-wrapper {
                ul {
                    padding: 0 $borderDimTablet;
                }
            }
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    product-filter-maturity {
        .list {
            border-radius: 0 0 4px 4px;
            width: calc(25% - 25px);
            left: calc(75% - 15px);
            &:before {
                left: 0;
                width: 100%;
            }
            .calendar {
                margin: 9px $filterPadding 20px $filterPadding;
            }
            .list-wrapper {
                margin: 0;
                min-height: 0;
                ul {
                    margin-bottom: 12px;
                    padding: 0 $filterPadding;
                    li {
                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }
}