image-component {
	> section {
		text-align: center;
	}
	img {
		max-width: 100%;
	}
	.normal-size-container {
		position: relative;
		display: inline-block;
		.toggle-fullscreen {
			display: none;
			width: 100%;
			height: 60px;
			bottom: 0;
			position: absolute;
			opacity: 0.8;
			background-color: #FAFAFA;
			cursor: pointer;
			font-family: $iconFont !important;
			&:before {
				color: #007db2;
				font-size: 33px;
				line-height: 55px;
				left: calc(50% - 10px);
				right: 50%;
				position: absolute;
			}
		}
		&:hover {
			.toggle-fullscreen {
				display: block;
			}
		}

	}
	.fullscreen-size-container {
		background-color: rgba(0,0,0,0.85);
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 99999;
		position: fixed;
		.close-action {
			margin-top: 45px;
			font-family: $iconFont!important;
			right: 0;
			position: absolute;

			cursor: pointer;
			margin-right: 15px;

			&:before {
				color: #FFF;
				font-size: 33px;
			}
		}
		.image-content {
			.image-square {
				max-width: 100%;
				border: 20px solid #fff;
				background: #fff;
				display: inline-block;
				margin: calc(10vh) auto 0;
				p, h4 {
					text-align: left;
				}
			}
		}
		&.closed {
			display: none;
		}
	}
}