/*------------------------------------*\
    #FORM
\*------------------------------------*/


/**
 * Default fieldset for input text elements.
 */
fieldset {
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
    border: none;

    input {
        box-sizing: border-box;
        width: 100%;
        height: 37px;
        padding: 0 10px;
        color: $textColorNotSoDark;
        font-family: $mainFont;
        font-size: $fontSizeSmall;
        outline: none;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: $backgroundColorWhite;
        border: 1px solid transparent;
    }

    input[type="checkbox"] {
        height: 18px;
        width: 18px;
    }
    
    
    /**
     * Error.
     */
    .error {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: -25px;
        height: 25px;
        line-height: 25px;
        font-size: $fontSizeNotSoTiny;
        color: $highlightColorError;
        
        i {
            margin-right: 6px;
        }
    }
    
    
    /**
     * Select group.
     * Show label above text.
     * Needs select with ng-model.
     * Label should be after select.
     */
    &.select-group {
        
        
        /**
         * Select box wrapper for styling.
         */
        select-box {
            display: inline-block;
            box-sizing: border-box;
            position: relative;
            width: 270px;
            height: 50px;
            line-height: 33px;
            background-color: $backgroundColorWhite;
            
            
            /**
             * Active status of select box.
             */
            &.active {
                select {
                    padding-top: 16px;
                    border-bottom: 2px solid $highlightColor;
                }
                
                label {
                    display: block;
                }
            }
            
            
            /**
             * Disabled status of select box.
             */
            &.disabled {
                &:before {
                    color: $textColorMain;
                }
            }
            
            
            /**
             * Select box icon.
             */
            &:before {
                position: absolute;
                z-index:1;
                top: 15px;
                right: 10px;
                font-family: $iconFont;
                font-size: 12px;
                color: $textColorDark;
                pointer-events: none;
            }
            
            select {
                padding: 15px 30px 0 0;
                box-sizing: border-box;
                width: 270px;
                height: 50px;
                line-height: 33px;
                font-family: $mainFont;
                font-size: $fontSizeMiddle;
                color: $textColorNotSoDark;
                border: none;
                box-shadow: none;
                background: none;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                text-indent: 0.01px;
                text-overflow: "";
                cursor: pointer;
                border-bottom: 1px solid $backgroundColorNotSoDark;
                
                &:disabled {
                    color: $textColorMain;
                    border-bottom-color: $highlightColorDark;
                }
                
                &:focus {
                    padding-top: 16px;
                    outline: none;
                    border-bottom: 2px solid $highlightColor;
                
                    + label {
                        color: $highlightColor;
                    }
                }
                
                
                /**
                 * Error.
                 */
                &.ng-touched.ng-invalid {
                    padding-top: 16px;
                    border-bottom: 2px solid $highlightColorError;

                    + label {
                        color: $textColorError;
                    }
                }
                
                
                /**
                 * Hide select arrow for > IE9.
                 */
                &::-ms-expand {
                    display: none;
                }
            }
            
            label {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                margin: 0;
                line-height: 25px;
                font-size: 14px;
                color: $backgroundColorNotSoDark;
            }
        }
    }
    
    
    /**
     * Text group.
     * Show label above text, instead of placeholder.
     * Needs input with placeholder and ng-model.
     * Label should be after input.
     */
    &.text-group {
        position: relative;
        
        input {
            box-sizing: border-box;
            padding: 15px 0 0 0;
            width: 100%;
            height: 50px;
            line-height: 33px;
            font-family: $mainFont;
            font-size: $fontSizeMiddle;
            border-bottom: 1px solid $backgroundColorNotSoDark;
            
            &::-webkit-input-placeholder,
            &::-ms-input-placeholder {
                color: $textColorNotSoDark;
            }
            
            &::-moz-placeholder,
            &:-moz-placeholder { 
                color: $textColorNotSoDark;
                opacity: 1;
            }
            
            &:focus {
                padding-top: 16px;
                color: $textColorDark;
                border-bottom: 2px solid $highlightColor;
                
                + label {
                    color: $highlightColor;
                }
            }
            
            &:disabled {
                color: $textColorMain;
                border-bottom-color: $highlightColorDark;
            }
            
            &.ng-not-empty, &.is-active {
                &.ng-dirty {
                    padding-top: 16px;
                    color: $textColorDark;
                    border-bottom: 2px solid $highlightColor;
                }
                
                + label {
                    display: block;
                }
            }
            
            /**
             * Error.
             */
            &.ng-touched.ng-invalid {
                padding-top: 16px;
                border-bottom: 2px solid $highlightColorError;

                + label {
                    color: $textColorError;
                }
            }
        }
        
        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
            margin: 0;
            -webkit-appearance: none;
        }
        input[type="number"] {
            -moz-appearance: textfield;
        }
        
        label {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            margin: 0;
            line-height: 25px;
            font-size: 14px;
            color: $backgroundColorNotSoDark;
        }
    }
    
    
    /**
     * Textarea group.
     * Show label above text, instead of placeholder.
     * Needs textarea with placeholder and ng-model.
     * Label should be after textarea.
     */
    &.textarea-group {
        position: relative;
        
        textarea {
            box-sizing: border-box;
            overflow: auto;
            padding: 15px 15px 15px 14px;
            width: 100%;
            min-height: 115px;
            font-family: $mainFont;
            font-size: $fontSizeMiddle;
            color: $textColorNotSoDark;
            outline: none;
            resize: none;
            border: 1px solid $backgroundColorNotSoDark;
            border-radius: 4px;
            
            &::-webkit-input-placeholder,
            &::-ms-input-placeholder {
                color: $textColorNotSoDark;
            }
            
            &::-moz-placeholder,
            &:-moz-placeholder { 
                color: $textColorNotSoDark;
                opacity: 1;
            }
            
            &:focus {
                padding: 14px 15px 15px 13px;
                color: $textColorDark;
                border: 2px solid $highlightColor;
            }
            
            &.ng-not-empty {
                padding-top: 30px;
                
                &.ng-dirty {
                    padding-left: 13px;
                    color: $textColorDark;
                    border: 2px solid $highlightColor;
                }
                
                + label {
                    display: block;
                }
            }
            
            /**
             * Error.
             */
            &.ng-touched.ng-invalid {
                padding: 13px 15px 15px 13px;
                border: 2px solid $highlightColorError;
            }
        }
        
        label {
            display: none;
            position: absolute;
            top: 4px;
            left: 15px;
            margin: 0;
            line-height: 25px;
            font-size: 14px;
            color: $backgroundColorNotSoDark;
        }
    }
    
    
    /**
     * Checkbox group.
     */
    &.checkbox-group {
        label {
            float: left;
            line-height: 19px;
            color: $textColorNotSoDark;
            font-size: $fontSizeRegular;
            user-select: none;
            cursor: pointer;
        }
        
        input {
            opacity: 0;
            position: absolute;
            z-index: 12;
            width: 18px;
            height: 18px;
            cursor: pointer;
            
            :checked,
            :focus {
                outline: none !important;
            }
            
            & + .checkbox-label {
                position: relative;
                z-index: 11;
                display: inline-block;
                margin: 0 0 0 18px;
                padding: 0 0 0 10px;
                min-width: 21px;
                min-height: 15px;
            }
            
            &:checked + .checkbox-label {
                color: $highlightColor;
            }
            
            & + .checkbox-label:before {
                content: "";
                position: absolute;
                left: -18px;
                width: 16px;
                height: 16px;
                line-height: 16px;
                font-family: $iconFont;
                font-size: 11px;
                text-align: center;
                background: $backgroundColorWhite;
                border: 1px solid $backgroundColorDark;
            }

            &:checked + .checkbox-label:before {
                content: "\e900";
            }
        }
    }
    /**
     * Radio group.
     */
    &.radio-group {
        label {
            float: left;
            line-height: 19px;
            color: $textColorNotSoDark;
            font-size: $fontSizeRegular;
            user-select: none;
            cursor: pointer;
        }
        
        input {
            opacity: 0;
            position: absolute;
            z-index: 12;
            width: 18px;
            height: 18px;
            cursor: pointer;
            
            :checked,
            :focus {
                outline: none !important;
            }
            
            & + .radio-label {
                position: relative;
                z-index: 11;
                display: inline-block;
                margin: 0 0 0 18px;
                padding: 0 0 0 10px;
                min-width: 21px;
                min-height: 15px;
            }
            
            &:checked + .radio-label {
                color: $highlightColor;
            }
            
            & + .radio-label:before {
                content: "";
                position: absolute;
                left: -18px;
                width: 16px;
                height: 16px;
                line-height: 16px;
                font-family: $iconFont;
                font-size: 11px;
                text-align: center;
                background: $backgroundColorWhite;
                border: 1px solid $backgroundColorDark;
                border-radius: 50%;
            }

            &:checked + .radio-label:after {
                border-radius: 50%;
                content: "";
                position: absolute;
                background: $highlightColor;
                width:12px;
                height:12px;
                top: 3px;
                left: -15px;
            }
        }
    }
    

    /**
     * Buttons within a fieldset.
     */
    button + button {
        margin-top: 15px;
    }
}

fieldset + fieldset {
    margin-top: 15px;
}


/**
 * Set enough space between fieldsets to show validation messages.
 */
form[novalidate] fieldset + fieldset {
    margin-top: 25px;
}


/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {}


/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {}
