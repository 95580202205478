/*------------------------------------*\
    #MULTIPLE-LINKS
\*------------------------------------*/

multiple-links {
    section {
        padding: 0 $borderDimMobile;
    }
    
    
    /**
     * Links.
     */
    ul {
        margin-top: 30px;
        
        li {
            a {
                display: flex;
                align-items: center;
                padding: 0 10px;
                height: 80px;
                line-height: 22px;
                font-size: $fontSizeMiddle;
                color: $highlightColor;
                text-align: center;
                background: $backgroundColorMoreBright;
                box-shadow: 0 1px 3px 0 rgba(0,0,0,0.25);
                
                &:hover {
                    text-decoration: none;
                }
                
                span {
                    width: 100%;
                    display: block;
                }
            }
        }
        
        li + li {
            margin-top: 5px;
        }
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    h2 {
        line-height: 54px;
        font-size: 48px;
        padding: 50px 0 0 0;
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    multiple-links {
        section {
            padding: 0 $borderDimDesktop 70px $borderDimDesktop;
            background: $backgroundColorWhite;
        }
        
        h2 {
            line-height: 54px;
            font-size: $fontSizeTall;
            padding: 50px 0 0 0;
        }
        
        
        /**
         * Links.
         */
        ul {
            display: flex;
            justify-content: center;
            margin-top: 40px;
            width: 100%;

            li {
                width: 180px;

                a {
                    height: 180px;
                    line-height: 28px;
                    font-size: $fontSizeBig;
                    background: $backgroundColorMoreBright;
                    
                    &:hover {
                        box-shadow: 0 3px 9px 0 rgba(0,0,0,0.25);
                    }
                }
            }
            
            li + li {
                margin: 0 0 0 20px;
            }
        }
    }
}