/*------------------------------------*\
    #MAIN-HEADER
\*------------------------------------*/

.show-menu sidebar-mobile {
    display: block;
}

sidebar-mobile {
    display: none;

    .navigation-container-mobile {
        background: $backgroundColorWhite;
        transform: translate(0, 0);
        visibility: visible;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 1030;
        width: calc(100vw - 50px);

        &:before {
            content: "";
            position: fixed;
            left: -50px;
            top: 0;
            height: calc(100vh + 50px);
            width: 50px;
            background: rgba(0,0,0,.81);
        }

        i.icon-menu {
            right: 0;
            top: 0;
            position: absolute;
            z-index: 999;
        }
    }

    .navigation-list-mobile {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 10rem;
        left: 0;
        z-index: 0;
        padding: 1.5rem;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);

        search, menu, .menu-disclaimer {
            display: block;
        }
        search {
            top: 54px;
        }
        menu, .menu-disclaimer {
            top: 135px;
            .button-back {
                width: 60%;
            }
        }


    }

    .sticky-block {
        text-align: center;
        position: absolute;
        top: auto;
        right: 0;
        bottom: 0;
        left: 0;
        box-sizing: border-box;
    }

    .sticky-block-content {
        display: block;
        width: 100%;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        user-select: none;
        flex-shrink: 0;
        border-radius: 0;
        -webkit-transition: box-shadow 0.05s ease-in-out;
        transition: box-shadow 0.05s ease-in-out;


        locale-selector {
            position: relative;
            top: 0;
            display: block;
            width: 100%;
            right: 0;
            left: 0;
            bottom: 0;
            background-color: $backgroundColorWhite;
        }
    }

}

/* For iOS to make search results scroll smooth */
@media screen and (-webkit-min-device-pixel-ratio:0) {
    sidebar-mobile {
        .navigation-list-mobile {
            form .results {
                overflow: scroll;
                -webkit-overflow-scrolling: touch;
            }
        }
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    sidebar-mobile {
        .navigation-container-mobile {
            width: 50%;
            &:before {
                width: 100%;
                left: -100%;
            }
            i.icon-menu {
                right: 8px;
            }
            menu, .menu-disclaimer {
                width: 100%;

                &:before {
                    display: none;
                }
            }
            search {
                width: 100%;
            }
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    sidebar-mobile {
        display: none;
    }
}