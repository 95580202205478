/*------------------------------------*\
    #ACCORDION
\*------------------------------------*/

accordion {
    section {
        padding: 0 $borderDimMobile;
    }
    
    
    
    /**
     * Accordion.
     */
    ul {
        margin-top: 30px;
        
        li {
            margin-bottom: 2px;
            width: 100%;
            
            &.open,
            &:last-child {
                margin-bottom: 0;
            }

            .content p {
                a.iconfont.download-pdf {
                    line-height: 29px;
                }
                &:last-child a.iconfont.download-pdf {
                    border-bottom: none;
                }
            }
            .content p+p {
                margin-top: 0;
            }
            .content ul {
                margin-top: 0;

                li {
                    &:last-child {
                        a {
                            border-bottom: none;
                        }
                    }
                }
            }
            
            
            /**
             * Header.
             */
            header {
                display: flex;
                align-items: center;
                padding: 0 20px;
                height: 50px;
                color: $textColorWhite;
                cursor: pointer;
                background: $highlightColor;
                
                .icon-plus {
                    margin-right: 10px;
                    font-size: $fontSizeTiny;
                
                    &:before {
                        color: $textColorWhite;
                    }
                }
                
                span {
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    line-height: 50px;
                    font-size: $fontSizeMiddle;
                }
            }
            
            &.open header .icon-plus:before {
                content: "\e916";
            }
            
            
            /**
             * Content.
             */
            .content {
                @extend html-text;
                
                display: none !important;
                padding: $paddingDimMobile $paddingDimMobile $paddingDimMobile $paddingDimMobile !important;
            }
            
            &.open .content {
                display: block !important;
            }
        }
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-MOBILE
\*------------------------------------*/

@include mediaQueryMobile {
    accordion {
        ul li {
            header span {
                font-size: $fontSizeRegular;
            }
            .content{
                padding: 20px 0 30px 0 !important;
                p a.iconfont.download-pdf {
                    padding: 40px 0 40px 45px;
                    font-size: $fontSizeRegular;
                }
            }
        }

    }
}


/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    accordion {
        section {
            padding: 0 $borderDimDesktop;
            
            ul {
                li {
                    .content p {
                        a.iconfont.download-pdf {
                                margin: 0;
                                width: 80%;
                        }
                        &:last-child a.iconfont.download-pdf {
                            border-bottom: none;
                        }
                    }

                    .content ul {
                        margin-top: 0;

                        li {
                            a.iconfont.download-pdf {
                                margin: 0;
                                width: 80%;
                            }
                            &:last-child {
                                a {
                                    border-bottom: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        
        h2 {
            line-height: 54px;
            font-size: $fontSizeTall;
        }
        
        
        /**
         * Accordion.
         */
        ul {
            margin-top: 40px;
        }
    }
}