/*------------------------------------*\
  #PROFILER
\*------------------------------------*/

profiler {

    position: fixed;
    z-index: 2147483640;
    left: 0;
    top: 0;
    font-family: Arial, Liberation Sans, DejaVu Sans, sans-serif;
    font-size: 100%;
    color: $textColorShaded;

    .profiler-button {

        float: left;
        padding: 4px 7px;
        background: #3ae4c6;
        border-right: 1px solid #d0cece;
        border-bottom: 1px solid #b9b8b8;
        box-shadow: 0px 0px 6px #349bbd;
        text-align: center;
        cursor: pointer;
        -moz-border-radius-bottomright: 10px;
        border-bottom-right-radius: 5px;
        font-family: Consolas, monospace, serif;

        a {
            color: #efefef;
            text-decoration: none;
            white-space: nowrap;
            float: left;
        }
    }

    .switch {
        width: 35px;
        height: 16px;
        line-height: 16px;
        float: right;
        margin: 0px 10px;
    }

    .on {
        background-color: #3c9800;
    }

    .off {
        background-color: #980708;
    }
}