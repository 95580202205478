/*------------------------------------*\
    #SEPARATOR
\*------------------------------------*/

separator {
    display: block;
    
    hr {
        margin: 50px auto 40px auto;
        width: 88px;
        height: 6px;
        background: $backgroundColorMoreBright;
        border: 0;
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryMobile {
    separator {
        hr.smartphone-only {
            display: block!important;
        }
    }
}

@include mediaQueryMediumRegular {
    separator {
        hr.tablet-only {
            display: block!important;
            margin-bottom: 0;
        }
    }
}

@include mediaQueryRegular {
    separator {
        hr {
            margin: 80px auto 0px auto;
        }
    }
}