/*------------------------------------*\
    #GLOSSARY
\*------------------------------------*/

#glossary {
    header {
        position: relative;
        padding: 30px $borderDimMobile 0 $borderDimMobile;
        
        h1 {
            line-height: 32px;
            font-size: 28px;
            font-family: $mainFont;
            margin: 0;
        }
        
        fieldset {
            position: relative;
            
            i {
                position: absolute;
                top: 40px;
                left: 10px;
                font-size: 18px;
                &:before {
                    color: $textColorDark;
                }
            }

            input {
                margin-top: 30px;
                padding-left: 37px;
                height: 40px;
                line-height: 40px;
                font-size: $fontSizeMiddle;
                color: $textColorNotSoDark;
                border: 1px solid $backgroundColorBright;
                border-radius: 4px;
                -webkit-appearance: none;
                
                &::placeholder {
                    color: $textColorNotSoDark;
                    opacity: 1;
                }
                
                &::-webkit-input-placeholder,
                &::-moz-placeholder,
                &::-moz-p,
                &:-ms-input-placeholder,
                &::-ms-input-placeholder {
                    color: $textColorNotSoDark;
                }
            }

            /* Hidden search submit button for iOS keyboard */
            input[type=submit] {
                visibility: hidden;
                width: 0;
                height: 0;
                position: absolute;
            }
        }
        
        
        /**
         * Glossary alphabet.
         */
        .glossary-alphabet {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 162px;
            right: 0;
            padding: 5px 0;
            background: $backgroundColorVeryBright;
            
            &.sticky {
                position: fixed;
                transform: translate3d(0, 82px, 0);
                top: 0;
            }
            
            li {
                width: 18px;
            }
            
            li + li {
                margin-top: 3px;
            }
            
            button {
                padding: 0;
                height: 18px;
                line-height: 18px;
                font-family: $mainFont;
                font-size: $fontSizeMiddle;
                color: $textColorNotSoBlack;
                text-transform: uppercase;
                background: transparent;
                
                &.disabled {
                    color: $textColorMain;
                    cursor: auto;
                }
                
                &.active {
                    color: $highlightColor;
                }
            }
        }
        .floating-letter {
            display: none;
            position: absolute;
            right: 50px;
            top: 300px;
            background: #007DB2;
            width: 50px;
            height: 50px;
            -webkit-border-radius: 50px;
            -moz-border-radius: 50px;
            border-radius: 50px;
            text-align: center;
            line-height: 50px;
            color: #FFFFFF;
            text-transform: uppercase;
            font-size: 28px;
            z-index: 9;
            &.sticky {
                top: 220px;
                position: fixed;
            }
        }
    }
    
    section {
        padding: 30px $borderDimMobile 0 $borderDimMobile;
        
        
        /**
         * Glossary list.
         */
        .glossary-list {
            & > li + li horizontal-line {
                margin-top: 50px;
            }
            
            
            /**
             * Glossary list row.
             */
            &-row {
                display: flex;
                flex-wrap: wrap;
                padding-right: 10px;
                
                & + li {
                    margin-top: 30px;
                }
                
                strong {
                    width: 100%;
                    line-height: 24px;
                    color: $textColorDark;
                    font-size: $fontSizeRegular;
                }
                
                span {
                    display: block;
                    margin-top: 5px;
                    line-height: 24px;
                    color: $textColorDark;
                    font-size: $fontSizeRegular;
                    font-family: $mainFontLight;
                }
            }
            
            
            /**
             * No results.
             */
            li.no-results {
                display: block;
                color: $textColorError;
            }
            
            li + li.no-results {
                display: none;
            }
        }
        
        
        /**
         * Horizontal line.
         */
        horizontal-line {
            margin-bottom: 20px;
            
            span {
                font-family: $mainFontBold;
                font-size: $fontSizeMiddle;
                color: $textColorDark;
                text-transform: uppercase;
            }
        }
    }
    
    footer {
        padding: 30px $borderDimMobile 0 $borderDimMobile;
        
        p {
            line-height: 18px;
            font-size: $fontSizeRegular;
        }
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {}


/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    #glossary {
        header {
            padding: $borderDimDesktop $borderDimDesktop 0 $borderDimDesktop;
            background: $backgroundColorMoreBright;
            
            h1 {
                line-height: 65px;
                font-size: 56px;
                margin: 0;
            }
            
            form {
                text-align: center;
                
                fieldset {
                    width: 50%;    
                    max-width: 580px;

                    i {
                        top: 55px;
                    }

                    input {
                        margin-top: 40px;
                        width: 100%;
                        height: 50px;
                        line-height: 50px;
                        color: $textColorNotSoDark;
                        border-color: $textColorNotSoDark;
                    }
                }
            }
            
            
            /**
             * Glossary alphabet.
             */
            .glossary-alphabet {
                flex-direction: row;
                position: relative;
                top: 0;
                margin-top: 30px;
                padding: 0;
                background: none;
                
                li {
                    position: relative;
                    width: 41px;
                }
                
                li:first-child {
                    width: 88px;
                }
                
                li + li {
                    margin-top: 0;
                    margin-left: 5px;
                    
                    button {
                        text-transform: uppercase;
                    }
                }
                
                button {
                    height: 40px;
                    line-height: 38px;
                    color: $textColorDark;
                    background: $backgroundColorBright;
                    border-top: 3px solid transparent;

                    &.active {
                        font-family: $mainFontBold;
                        color: $textColorDark;
                        background: $backgroundColorWhite;
                        border-color: $highlightColor;
                    }
                    
                    &:hover:not(.active):not(.disabled) {
                        box-shadow: 0 1px 3px 0 rgba(0,0,0,0.25);
                    }
                    
                    &.active:after,
                    &:hover:not(.disabled):after {
                        content: "";
                        display: block;
                        position: absolute;
                        bottom: -5px;
                        width: 100%;
                        height: 5px;
                        background: $backgroundColorWhite;
                    }
                }
            }
        }
        
        section {
            padding: $borderDimDesktop 0 0 0;


            /**
             * Glossary list.
             */
            .glossary-list {
                & > li + li horizontal-line {
                    margin-top: 80px;
                }
            

                /**
                 * Glossary list row.
                 */
                &-row {
                    flex-wrap: nowrap;
                    padding: 15px 0 20px 0;
                    border-bottom: 1px solid $backgroundColorNotSoDark;
                    
                    & + li {
                        margin-top: 0;
                    }

                    strong {
                        padding-right: 10px;
                        width: 20%;
                        line-height: 25px;
                        font-size: $fontSizeMiddle;
                    }

                    span {
                        margin-top: 0;
                        width: 80%;
                        line-height: 25px;
                        font-size: $fontSizeMiddle;
                    }
                }
            }


            /**
             * Horizontal line.
             */
            horizontal-line {
                span {
                    font-size: $fontSizeBig;
                    color: $textColorBlack;
                }
            }
        }
        
        footer {
            padding: $borderDimDesktop $borderDimDesktop 0 $borderDimDesktop;
        }
    }
}