/*------------------------------------*\
    #SCENESLIDER
\*------------------------------------*/

sceneslider {
    .slider {
        position: relative;
        min-height: 200px;

        &-slide {
            opacity: 0;
            width: 100%;
            height: 200px;
            position: absolute;
            -webkit-transition: visibility 0s linear 0.5s, opacity 0.5s linear;
            -moz-transition: visibility 0s linear 0.5s, opacity 0.5s linear;
            -o-transition: visibility 0s linear 0.5s, opacity 0.5s linear;
            transition: visibility 0s linear 0.5s, opacity 0.5s linear;
            visibility: hidden;

            &-img {
                width: 100%;
                min-width: 375px;
                -webkit-user-drag: none;
            }

            &-box {
                position: absolute;
                width: 100%;
                top: 243px;

                h2 {
                    margin-top: 5px;
                    color: $highlightColor;
                    text-align: center;
                }

                p {
                    margin: 20px $borderDimMobile 0 $borderDimMobile;
                    color: $backgroundColorNotSoDark;
                    line-height: 24px;
                    text-align: center;
                    font-family: $mainFontLightSerif;
                }
                
                .button {
                    position: relative;
                    z-index: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 30px auto 0;
                    width: 65%;
                    width: auto;
                    width: -webkit-fit-content;
                    width: -moz-fit-content;
                    width: fit-content;
                    white-space: nowrap;
                    display: table;
                }
            }

            &.show {
                visibility: visible;
                opacity: 1;
                transition-delay: 0s;
            }
        }

        &-dots {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            width: 100%;
            height: 34px;
            top: 200px;

            li {
                display: inline-flex;
                margin: 0 10px;

                a {
                    box-sizing: border-box;
                    width: 16px;
                    height: 16px;
                    background: $backgroundColorWhite;
                    border-radius: 50%;
                    border: 1px solid $backgroundColorBright;

                    span {
                        display: none;
                    }
                }

                a.active {
                    font-weight: bold;
                    background: $highlightColor;
                    border: none;
                }
            }
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-MOBILE
\*------------------------------------*/

@include mediaQueryMobile {
    sceneslider {
        .slider {
            &-slide {
                &-box {
                    p {
                        font-size: $fontSizeRegular;
                    }
                }
            }
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    sceneslider {
        .slider {
            &-slide {
                height: 325px;
                
                &-box {
                    top: 368px;
                }
            }

            &-dots {
                top: 325px;
            }
        }
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    sceneslider {
        .slider {
            position: relative;
            height: 475px;

            &-slide {
                height: 440px;
                
                &-img {
                    min-width: 1280px;
                }

                &-box {
                    top: 60px;
                    max-height: 230px;
                    width: 460px;
                    padding: 30px 20px;
                    background-color: #FFFFFF;
                    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);

                    h2 {
                        margin: 0;
                        line-height: 42px;
                        font-size: $fontSizeNotSoTall;
                        text-align: left;
                        padding: 0 0 20px 0;
                    }

                    p {
                        margin: 0;
                        text-align: left;
                    }
                    
                    .button {
                        position: absolute;
                        bottom: -70px;
                        width: auto;

                        &.left {
                            left: 0;
                        }

                        &.right {
                            right: 0;
                            left: auto;
                        }
                    }
                }

                &-box.left {
                    left: 90px;
                }

                &-box.right {
                    right: 90px;
                }
            }

            &-dots {
                top: auto;
                bottom: 0;
                border-bottom: 1px solid #E3E3E3;
                li {
                    display: inline-block;
                    margin: 0;
                    
                    a {
                        text-decoration: none;
                        border-radius: 0;
                        border: none;
                        height: 35px;
                        width: 111px;
                        color: #333333;
                        font-size: 18px;
                        line-height: 35px;
                        text-align: center;
                        span {
                            display: block;
                        }
                    }

                    a.active {
                        text-decoration: none;
                        background: none;
                        color: $highlightColor;

                        span {
                            position: relative;

                            &:after{
                                content: '';
                                position: absolute;
                                left: calc(50% - 10px);
                                width: 0;
                                height: 0;
                                border-left: 10px solid transparent;
                                border-right: 10px solid transparent;
                                border-bottom: 10px solid #ffffff;
                                clear: both;
                                top: -10px;
                            }
                        }
                    }
                }
                
                li + li {
                    margin-left: 60px;
                }
            }

            &-arrow {
                position: absolute;
                top: 45%;
                color: $highlightColorBright;
                font-size: 25px;
                opacity: 0;
                transition: opacity 0.5s linear;

                &.prev {
                    right: 40px;
                }

                &.next {
                    left: 40px;
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .slider:hover .slider-arrow {
            opacity: 1;
        }
    }
}