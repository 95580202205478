#newsletter {
	max-width: 800px;
	margin: 0 auto;
	padding: 0 10px;
	width: 100%;

	.newsletter-title.headline h1 {
		margin: 80px 0 7px 0;
		letter-spacing: 0;
	}

	.newsletter-subtitle {
		padding-bottom: 32px;
		text-align: center;
	}

	.newsletter-choose-type {
		padding-bottom: 28px;
		text-align: center;
	}

	.external-link {
		color: $highlightColor;
		font-family: $mainFontMedium;
		font-size: 18px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 25px;
		padding-left: 15px;
	}

	.newsletter-form-container {
		max-width: 500px;
		margin: 0 auto;
		width: 100%;

		fieldset {
			.error {
				line-height: 18px;
				align-items: start;

				i {
					margin-top: 3px;
				}
			}

			&.text-group {
				input {
					border-bottom: 1px solid #666;
					height: 45px;

					&:focus+label {
						color: #333333;
					}

					&.ng-not-empty,
					&:focus {
						border-bottom: 2px solid #333333;
						color: #333333;
					}

					&.ng-touched.ng-invalid {
						border-bottom: 2px solid #D74B32;

						+label {
							color: #666666;
						}
					}
				}

				&.firstname-fieldset {
					margin-top: 42px;
				}

				&.company-fieldset {
					margin-top: 35px;
				}

				&.email-fieldset {
					margin-top: 23px;
				}
			}

			&.select-group {
				select-box {
					&.active {
						select {
							border-bottom: 2px solid #333333;
							color: #333333;
						}
					}

					select:focus+label {
						color: #666666;
					}

					select {
						height: 48px;
					}
				}

				&.language-fieldset {
					margin-top: 15px;
				}

				&.salutation-fieldset {
					select-box select {
						height: 55px;
					}

					.error {
						bottom: -32px;
						left: 2px;
					}
				}
			}

			&.checkbox-group {
				label {
					color: #333333;
				}

				input+.checkbox-label:before {
					top: 3px;
				}

				&.privacy-fieldset {
					margin-top: 46px;

					input {
						+.checkbox-label {
							color: #333333;
							font-size: 18px;
							font-weight: 300;
							letter-spacing: 0;
							line-height: 23px;

							a {
								color: #007DB2;
							}
						}

						&:checked+.checkbox-label:before {
							color: #007DB2;
						}
					}

					.error {
						bottom: -35px;
					}
				}
			}

			&.button-group {
				margin-top: 25px;
				text-align: center;

				button {
					display: inline-block;
					font-size: 16px;
					letter-spacing: 0.5px;
					line-height: 21px;
					text-align: center;
				}
			}
		}

		.error i.icon-error:before {
			font-size: 13px;
			width: 12px;
		}

		.newsletter-type {
			.error {
				top: -20px;
				bottom: initial;
			}

			.checkbox-option {
				margin-top: 15px;

				&:first-child {
					margin-top: 0;
				}

				label {
					float: none;
				}
			}
		}

		.newsletter-form-subtitle {
			margin: 50px 0 15px 0;
			font-family: $mainFontMedium;
		}

		.newsletter-required-notice {
			margin-top: 46px;
			font-size: 18px;
			font-weight: 300;
			letter-spacing: 0;
			line-height: 25px;
		}
	}
}
