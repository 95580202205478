/*------------------------------------*\
    #HTML-TEXT
\*------------------------------------*/

html-text {
    display: block;
    padding: 0 $borderDimMobile;
    
    
    /**
     * Headlines.
     */
    h1 {
        margin: 40px 0 20px 0;
        text-align: inherit;
    }

    h2 {
        margin: 0 0 20px 0;
        text-align: inherit;
    }

    h3 {
        margin: 0px 0 40px 0;
    }
    
    p + h3 {
        margin-top: 35px;
    }

    h4 {
        margin: 20px 0 10px 0;
    }

    h5 {
        margin: 30px 0 20px 0;
    }
    
    h1 + h2 {
        padding: 0;
        margin-bottom: 40px;
    }
    
    p {
        font-size: $fontSizeRegular;
    }
    
    /**
     * Regular font.
     */
    .regular-font {
        line-height: 24px;
        font-family: $mainFontLight;
        font-size: $fontSizeRegular;
    }
    
    .regular-font-bold {
        line-height: 18px;
        font-family: $mainFontMedium;
        font-size: $fontSizeRegular;
    }

    .regular-font-quote {
        line-height: 24px;
        font-family: $mainFontLight;
        font-size: $fontSizeMiddle;
    }

    .regular-font-extended {
        line-height: 18px;
        font-family: $mainFontLight;
        font-size: $fontSizeRegular;
    }

    .regular-font-hint {
        line-height: 14px;
        font-size: $fontSizeVeryTiny;
        color: $textColorNotSoDark;
    }

    
    table-font {
        line-height: 18px;
        font-family: $mainFontLight;
        font-size: $fontSizeRegular;
        color: $textColorNotSoDark;
    }
    
    
    a {
        font-family: $mainFontMedium;
        font-size: $fontSizeMiddle;
        color: $highlightColor;
    }
    
    
    img {
        margin: 24px 0;
        max-width: 100%;
        height: auto;
    }
    
    
    /**
     * Embeded video.
     */
    p.video {
        position: relative;
        padding-bottom: 56.25%;
        height: auto;
        
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            max-width: 100%;
            height: 100%;
        }
    }
    
    
    /**
     * Iframe newsletter.
     * Static height, iframe resizer did not work out of the box.
     */
    iframe.iframe-newsletter-small,
    iframe.iframe-newsletter-big {
        width: 100%;
        border: 0;
    }
    
    iframe.iframe-newsletter-small {
        height: 135px;
    }
    
    iframe.iframe-newsletter-big {
        height: 630px;
    }
    
    
    /*
     * List styles.
     */
    ul,
    ol {
        margin: 18px 0 0 0;
        padding: 0 0 0 17px;
        
        li {
            font-family: $mainFontLight;
            font-size: $fontSizeRegular;
            line-height: 24px;
            padding-left: 7px;
            padding-bottom: 11px;
        }
    }
    
    ul {
        list-style-type: disc;
        padding: 0 0 0 15px;
        li {
            padding-left: 0px;
        }
        &.list-dash {
            padding-left: 0;
            list-style-type: none;
            
            li:before {
                content: "-";
                display: inline-block;
                margin-right: 12px;
            }
        }
    }
    
    ol {
        list-style-type: decimal;
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    html-text {
        padding: 0 $borderDimTablet;
        
        h1 {
            margin: 40px 0 20px 0;
            text-align: inherit;
        }
        h2 {
            margin: 0 0 30px 0;
        }

        p + h3 {
            margin-top: 50px;
        }
        
        
        img {
            &[style*="float:right"],
            &[style*="float: right;"] {
                margin-left: 40px;
            }
            
            &[style*="float:left"],
            &[style*="float: left;"] {
                margin-right: 40px;
            }
        }
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    html-text {
        padding: 0 $borderDimDesktop;
        
        
        section {
            padding: 0 6.3vw;
        }
        
        h1 {
            margin: 80px 0 20px 0;
            text-align: inherit;
        }
        /**
         * Headlines.
         */
        h4 {
            margin: 40px 0 10px 0;
        }
        
        h5 {
            margin: 80px 0 30px 0;
        }
        
        p {
            font-size: $fontSizeMiddle;
        }
        
        /**
         * Regular font.
         */
        .regular-font {
            line-height: 25px;
            font-size: $fontSizeMiddle;
        }

        .regular-font-bold {
            line-height: 25px;
            font-size: $fontSizeMiddle;
        }

        .regular-font-quote {
            line-height: 44px;
            font-size: $fontSizeNotSoTall;
        }

        .regular-font-extended {
            line-height: 18px;
            font-size: $fontSizeRegular;
        }

        .regular-font-hint {
            line-height: 18px;
            font-size: $fontSizeNotSoTiny;
            color: $textColorNotSoDark;
        }


        table-font {
            line-height: 18px;
            font-size: $fontSizeNotSoTiny;
        }
        
        
        img {
            &[style*="float:right"],
            &[style*="float: right;"] {
                margin-right: -6.3vw;
                margin-left: 60px;
            }
            
            &[style*="float:left"],
            &[style*="float: left;"] {
                margin-right: 60px;
                margin-left: -6.3vw;
            }
        }
        
        
        /*
         * List styles.
         */
        ul,
        ol {
            li {
                font-size: $fontSizeMiddle;
            }
        }
    }
}