@-webkit-keyframes pushUp {
    0% {
        color: #383838;
    }
    100% {
        color: green;
    }
}

@-webkit-keyframes pushDown {
    0% {
        color: #383838;
    }
    100% {
        color: red;
    }
}

@-webkit-keyframes pushUpFade {
    0% {
        background: #fff;
    }
    30% {
        background: #85b40c;
        color: #fff;
    }
    100% {
        background: #fff;
    }
}

@-webkit-keyframes pushDownFade {
    0% {
        background: #fff;
    }
    30% {
        background: #cc0000;
        color: #fff;
    }
    100% {
        background: #fff;
    }
}

.push-up {
    animation: pushUp 400ms none;
}

.push-down {
    animation: pushDown 400ms none;
}

.push-fade-up {
    -webkit-animation: pushUpFade 600ms ease-in-out;
}

.push-fade-down {
    -webkit-animation: pushDownFade 600ms ease-in-out;
}