/*------------------------------------*\
    #SEPARATOR
\*------------------------------------*/

spacer {
    display: block;
    height: 30px;
    hr {
        margin: 0;
        width: 100%;
        height: 30px;
        background: transparent;
        border: 0;
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    spacer {
        height: 45px;
        hr {
            height: 4px;
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    spacer {
        height: 80px;
        hr {
            height: 80px;
        }
    }
}