investment-ideas-component {
  > section {
    margin: 40px 50px 0 50px;

    .header {
      margin-bottom: 30px;
      span.text {
        color: #333333;
        font-size: 21px;
        line-height: 25px;
        font-weight: 300;
        text-align: center;
        width: 215px;
        margin: -18px auto auto auto;
        background: #FFF;
        display: block;
      }
      span.border {
        border-bottom: 2px solid #F5F6F8;
        width: 100%;
        margin-bottom: 4px;
        display: block;
      }
    }
    .custom-row {
      .block {
        width: 100%;
        float: none;
        display: inline-block;
        margin-bottom: 30px;
        min-height: 140px;
        &.odd {
          clear: both;
        }
        .image {
          float:left;
          margin-right: 20px;

          img {
            max-width: 180px;
            height: 140px;
          }
        }

        .text {
          .date {
            font-size: 16px;
            font-weight: 300;
            line-height: 18px;
            font-family: $mainFont;
          }

          .title {
            line-height: 22px;
            font-size: 18px;
            font-weight: 500;
            color: #007DB2;
            padding: 8px 0 10px 0;
            display: block;
          }
        }
        .labels {
          margin-bottom: 20px;

          > div > span:not(:last-child):after {
            content: ', ';
          }

          ul li {
            display: inline-block;
            margin-right: 4px;
            font-size: 16px;
            line-height: 18px;
            font-weight: 300;
            border-radius: 4px;
            margin-bottom: 12px;
          }
        }
      }
    }
    .footer {
      text-align: center;
      .button {
        display: inline-block;
      }
    }
  }
}
@include mediaQueryMobile {
  investment-ideas-component {
    > section {
      .custom-row {
        .block {
          .image {
            margin-right: 0;
            float: none;
            text-align: center;
          }

          .text {
            float: none;
            text-align: center;
            margin-top: 10px;
          }

          .labels {
            margin-bottom: 20px;
            text-align: center;
          }
        }
      }
    }
  }
}
@include mediaQueryRegular {
  investment-ideas-component {
    > section {
      .header {
        span {
          &:before,
          &:after {
            width: calc(50% - 136px);
          }
          &:after {
            margin-left: 107px;
          }
          &:before {
            margin-right: 107px;
          }

        }
      }
      .custom-row {
        .block {
          width: calc(50% - 20px / 2);
          float: left;
        }
      }
    }
  }
}