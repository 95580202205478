/*------------------------------------*\
    #MAIN-HEADER
\*------------------------------------*/

main-header {
    position: absolute;
    width: 100%;
    z-index: 1000;
    &.sticky {
        transform: translate3d(0, -#{$mainHeaderSizeMobile}, 0);
        position: fixed;
    }
    header {
        width: 100%;
        background: $headerColor;
        /**
         * Main header light area.
         */
        .light {
            display: flex;
            padding: 0 $borderDimMobile;
            height: $mainHeaderSizeMobile;
            line-height: $mainHeaderSizeMobile;
            background: $backgroundColorWhite;
            .beta {
                height: 30px;
                border-radius: 5px;
                background-color: #FFF8E6;
                position: absolute;
                right: $borderDimMobile;
                top: 9px;
                line-height: 25px;
                padding: 0 10px;
                &:before {
                    content: '\e922';
                    font-family: $iconFont;
                    font-size: 12px;
                    line-height: 18px;
                    display: inline-block;
                    background: #ffc107;
                    height: 18px;
                    width: 18px;
                    text-align: center;
                    color: $textColorWhite;
                }
                em {
                    color: $textColorDark;
                    font-family: $mainFontLight;
                    font-size: $fontSizeRegular;
                    font-style: normal;
                    &:last-child {
                        display: none;
                    }
                }
            }
        }
        /**
         * Main header dark area.
         */
        .dark {
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
            padding: 0 $borderDimMobile;
            height: $mainHeaderSizeMobile;
            line-height: $mainHeaderSizeMobile;
            /**
            * Main header slogan.
            */
            .slogan {
                font-size: $fontSizeMiddle;
                font-family: $mainFont;
                color: $textColorWhite;
            }
            .icon-menu {
                position: absolute;
                right: 0;
                top: $mainHeaderSizeMobile;
                z-index: 21;
                cursor: pointer;
            }
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    main-header {
        header {
            .light {
                padding: 0 $borderDimTablet;
                .beta {
                    right: $borderDimTablet;
                }
            }
            .dark {
                justify-content: flex-start;
                padding: 0 $borderDimTablet;
                .icon-menu {
                    right: calc(#{$borderDimTablet} - 12px);
                }
            }
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    main-header {
        &.sticky {
            transform: translate3d(0, -#{$mainHeaderSizeRegular}, 0);
        }
        header {
            max-width: $maxWidth;
            position: relative;
            .light {
                height: $mainHeaderSizeRegular;
                line-height: $mainHeaderSizeRegular;
                padding: 0 $borderDimDesktop;
                .beta {
                    top: 4px;
                    right: calc(50% - 150px);
                    em {
                        display: none;
                        &:last-child {
                            display: inline-block;
                        }
                    }
                }
            }
            .dark {
                height: 80px;
                line-height: 80px;
                padding: 0 $borderDimDesktop;
                .slogan {
                    font-size: $fontSizeBig;
                    line-height: 80px;
                    position: relative;
                    z-index: 1;
                }
                .icon-menu {
                    display: none;
                }
            }
        }
    }
}