/*------------------------------------*\
    #MENU
\*------------------------------------*/

.show-menu {
    main-header {
        transform: translate3d(0, -#{$mainHeaderSizeMobile}, 0);
        position: fixed;
    }
    menu, .menu-disclaimer {
        display: block;
    }
    
    .icon-menu:before {
        content: "\e906";
        color: $highlightColor;
    }
    
    .submenu-active {
        .button-back {
            display: block;
        }
        
        ul li.menu-entry {
            display: none;
        }
        
        ul li.menu-entry.show-submenu {
            display: list-item;
            font-family: $mainFontBold;
            
            .menu-entry {
                display: list-item;
                font-family: $mainFont;
                
                &:first-child {
                    a {
                        border-top: 2px solid $backgroundColorNotSoVeryVeryDark;
                    }
                }
                
                &.active {
                    font-family: $mainFontBold;
                }
            }
        }
    }
}

menu, .menu-disclaimer {
    margin: 0;
    padding: 0;
    display: none;
    position: absolute;
    z-index: 20;
    top: 185px;
    right: 0;
    width: calc(100vw - 50px);
    height: calc(100vh - 50px);
    
    .button-back {
        box-shadow: none;
        display: none;
        font-size: $fontSizeRegular;
        position: absolute;
        top: -129px;
        left: calc(#{$borderDimMobile} - 10px);
        z-index: 2;
        width: 50%;
        
        &:before {
            top: 0;
        }
        
        &:hover {
            box-shadow: none;
        }
    }
    
    .menu {
        position: relative;
        width: 100%;
        z-index: 1;
        
        /*
         * Menu entry
         */
        &-entry {
            padding: 0 $borderDimMobile;
            position: relative;
            color: $textColorShaded;
            cursor: pointer;
            font-size: $fontSizeMiddle;
            
            &:first-child a {
                border-top: 2px solid $backgroundColorNotSoVeryVeryDark;
            }
            
            a {
                border-bottom: 2px solid $backgroundColorNotSoVeryVeryDark;
                display: block;
                text-decoration: none;
                line-height: 22px;
                padding: 13px 0 13px;
            }
            
            &.active:not(.show-submenu) {
                font-family: $mainFontBold;
                
                &:before {
                    content: "";
                    display: block;
                    height: 52px;
                    background: $highlightColor;
                    width: 6px;
                    position: absolute;
                    left: 0;
                }
                
                a {
                    color: $highlightColor;
                }
            }
            
            &.active.show-submenu {
                .menu-submenu {
                    .menu-entry.active:before {
                        left: -$borderDimMobile;
                    }
                }
            }
            
            &.has-submenu {
                &:after {
                    content: '\e901';
                    color: $backgroundColorNotSoVeryVeryDark;
                    font-family: $iconFont;
                    font-size: 13px;
                    position: absolute;
                    right: calc(#{$borderDimMobile} + 10px);
                    top: 17px;
                }
                &.active {
                    font-family: $mainFontBold;
                }
                
                
                /**
                 * Submenu.
                 */
                .menu-submenu {
                    display: none;
                    position: absolute;
                    top: 49px;
                    background: $backgroundColorWhite;
                    width: calc(100% - #{$borderDimMobile}*2);
                    z-index: 25;
                    
                    &:before {
                        content: '';
                        display: block;
                        background: $backgroundColorWhite;
                        height: 50px;
                        width: 100%;
                        position: absolute;
                        top: -8px;
                    }
                    .menu-entry {
                        padding: 0;
                        font-family: $mainFont;
                        &:first-child a {
                            border-top: none;
                        }
                    }
                }
            }
            &.show-submenu {
                a {
                    &:after {
                        content: ':';
                    }
                }
                &:after {
                    display: none;
                }
                .menu-submenu {
                    display: block;
                    li a {
                        &:after {
                            content: '';
                        }
                    }
                }
            }
        }
        
        &-entry + .menu-entry {
            &.active:not(.has-submenu) {
                &:before {
                    height: 50px;
                }
            }
        }
    }
    
    &:before {
        content: "";
        position: fixed;
        left: 0;
        top: 0;
        height: calc(100vh + 50px);
        width: 50px;
        background: rgba(0, 0, 0, 0.81);
    }
    
    &:after {
        content: "";
        position: fixed;
        right: 0;
        top: 0;
        height: calc(100vh + 50px);
        width: calc(100vw - 50px);
        background: $backgroundColorWhite;
    }
}

.menu-disclaimer {
    top: 100px;
}

menu.main.submenu-active ul.menu li.menu-entry.show-submenu ul.menu-submenu li.menu-entry.active:before {
    left: -$borderDimMobile;
}

/*------------------------------------*\
  #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    menu, .menu-disclaimer {
        width: 50%;
        .menu {
            &-entry {
                &.has-submenu {
                    .menu-submenu {
                        width: calc(100% - #{$borderDimTablet}*2);
                    }
                    &:after {
                        right: calc(#{$borderDimTablet} + 10px);
                    }
                }
                
                &.active.show-submenu {
                    .menu-submenu {
                        .menu-entry.active:before {
                            left: -$borderDimTablet;
                        }
                    }
                }
            }
        }
        .button-back {
            left: $borderDimTablet;
        }
        &:before {
            width: 50%;
        }
        &:after {
            width: 50%;
        }
        .menu {
            &-entry {
                padding: 0 $borderDimTablet;
            }
        }
    }
    menu.main.submenu-active ul.menu li.menu-entry.show-submenu ul.menu-submenu li.menu-entry.active:before {
        left: -$borderDimTablet;
    }
}


/*------------------------------------*\
  #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    .submenu-active {
        .menu-entry.has-submenu.show-submenu.active {
            > a {
                border-bottom: 6px solid $textColorWhite;
                color: $highlightColor;
                &:after {
                    content: '';
                }
            }
        }
    }
    menu, .menu-disclaimer {
        display: flex;
        top: $mainHeaderSizeRegular;
        left: calc(#{$borderDimDesktop} + 290px);
        height: auto;
        width: 390px;
        &:before,
        &:after {
            display: none;
        }
        .menu {
            margin-top: 26px;
            &-entry {
                float: left;
                padding: 0 15px;
                
                &:before {
                    display: none !important;
                }
                
                a {
                    border-top: none !important;
                    border-bottom: none;
                    color: $textColorWhite;
                    position: relative;
                    padding: 6px 0 20px 0;
                    &:hover {
                        border-bottom: 6px solid $textColorWhite;
                    }
                }
                
                &.active:not(.show-submenu) {
                    a {
                        color: $textColorWhite;
                    }
                }
                
                &.active:not(.has-submenu) {
                    a {
                        border-bottom: 6px solid $textColorWhite;
                        color: $textColorWhite;
                    }
                }
                &.active {
                    a {
                        border-bottom: 6px solid $textColorWhite;
                    }
                }
                &.has-submenu {
                    &:after {
                        display: none;
                    }
                    .menu-submenu {
                        width: auto;
                        left: 0;
                        top: 43px;
                        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);
                        
                        .menu-entry {
                            padding: 0;
                            font-size: $fontSizeMiddle;
                            
                            a {
                                color: $linkColor;
                                line-height: 25px;
                                height: auto;
                                border: none;
                                padding: 2px 15px 7.5px 15px;
                                min-width: 270px;
                                
                                &:hover {
                                    font-family: $mainFontBold;
                                    color: $highlightColor;
                                }
                            }
                            
                            &.active {
                                a {
                                    font-family: $mainFontBold;
                                    color: $highlightColor;
                                }
                            }
                        }
                        
                        .menu-entry + .menu-entry {
                            a {
                                padding: 7.5px 15px;
                            }
                        }
                        
                        .menu-entry:last-of-type {
                            a {
                                padding-bottom: 20px;
                            }
                        }
                    }
                }
                
                &.show-submenu {
                    font-family: $mainFontBold;
                    background: $backgroundColorWhite;

                    a {
                        color: $highlightColor;
                        
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}