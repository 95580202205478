/*------------------------------------*\
    #PRODUCT-DETAIL
\*------------------------------------*/

#product-detail {
    &.icon-loading {
        &:before {
            background: $backgroundColorBright;
            height: 410px;
            width: 100%;
            top: 105px;
            left: 0;
            border-radius: 0;
            margin: 0;
        }
        &:after {
            position: fixed;
            top: 340px;
        }
    }
    background: $backgroundColorWhite;
    header {
        background: $backgroundColorMoreBright;
        overflow: hidden;
        padding: $borderDimMobile $borderDimMobile 50px $borderDimMobile;
        position: relative;
        h1 {
            min-height: 66px;
            margin: 12px 0 0 0;
        }
        .underlyings {
            font-family: $mainFontMedium;
            font-size: 18px;
            line-height: 22px;
            margin: 20px 0 10px 0;
        }
        .identifier {
            float: left;
            width: 60%;
            li {
                font-size: $fontSizeRegular;
                line-height: 24px;
            }
        }
        .documents {
            float: left;
            width: 40%;
            margin: 0 0 20px 0;
            > li {
                position: relative;
                .button-download {
                    min-width: 100px;
                    &:before {
                        left: 30px;
                    }
                }

                display: none;
                float: right;
                margin-bottom: 25px;
                &:first-child {
                    display: block;
                }
            }
        }
        .prices {
            clear: both;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 10px 0 0 0;
            width: 100%;
            li {
                background: $backgroundColorWhite;
                margin-left: $paddingDimMobile;
                padding: 10px;
                width: calc(47vw - #{$borderDimMobile} - 10px);
                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    display: block;
                    background: none;
                    line-height: 22px;
                    font-size: $fontSizeRegular;
                    text-align: right;
                    padding: 8px 0;
                    width: 100%;
                }
                .label {
                    display: block;
                    font-size: $fontSizeMiddle;
                    line-height: 25px;
                    text-align: right;
                }
                .value {
                    display: block;
                    font-size: $fontSizeNotSoTall;
                    line-height: 45px;
                    text-align: right;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
        notification-box {
            clear: both;
            margin: 20px 0 0 0;
        }
    }
    section {
        &.product-tabs-section {
            clear: both;
        }
        padding: 30px 0 0 0;
        overflow: hidden;
        tabs {
            tab {
                &:before {
                    display: none;
                }
                &.overview {
                    .columns {
                        display: table-footer-group;
                        &.main-column {
                            display: table-header-group;
                        }
                    }
                    section {
                        padding: 25px 0 0 0;
                        width: 100vw;
                        &:first-child {
                            padding-top: 0;
                        }
                    }
                }
                section {
                    padding: 0;
                }
            }
        }
        table tbody tr td {
            white-space: normal;
        }
        instrument-table table tbody tr td {
            white-space: nowrap;
        }
    }
    div.label-prices-indicative {
        margin-top: 25px;
        margin-left: 16px;
        color: $textColorMain;
        font-family: $mainFontLight;
        font-size: 12px;
        line-height: 14px;
    }

    .label-prices-indicative.footnote-text {
        margin-top: 0;
    }

    .cushions {
        padding: 15px 0;
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    #product-detail {
        header {
            .identifier {
                margin-top: 20px;
            }
        }
        .overview section {
            h2 {
                font-size: $fontSizeMiddle;
                font-family: $mainFontMedium;
            }
        }
        .keyfigures section {
            h2 {
                font-size: $fontSizeBig;
                line-height: 28px;
                margin-top: 0;
                padding-top: 0;
                margin-bottom: 10px;
            }
        }
        section {
            padding: $borderDimTablet 0 0 0;
            tabs {
                nav {
                    ul {
                        li {
                            flex-grow: 1;
                        }
                    }
                }
            }
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-MOBILE
\*------------------------------------*/

@include mediaQueryMobile {
    #product-detail {
        header .identifier {
            font-size: $fontSizeRegular;
            margin-top: 20px;
        }

        .overview section {
            h2 {
                font-size: $fontSizeMiddle;
                font-family: $mainFontMedium;
            }
            .full-column {
                .column.column-scrollable {
                    overflow: scroll;
                }
            }
        }

        .keyfigures section {
            h2 {
                font-size: $fontSizeBig;
                line-height: 28px;
                margin-top: 0;
                margin-bottom: 10px;
            }
        }
        section simple-table table tbody tr td {
            font-size: $fontSizeRegular;
        }
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    #product-detail {
        &.icon-loading {
            &:before {
                top: 120px;
            }
        }
        header {
            overflow: auto;
            padding: 30px $borderDimDesktop;
            min-height: 515px;
            h1 {
                text-align: left;
                margin: 15px 0 0 0;
                min-height: 120px;
                width: calc(60% - 150px / 2);
            }
            .underlyings {
                margin: 50px 0 0 0;
                width: calc(60% - #{$borderDimDesktop});
                font-family: $mainFont;
                font-size: $fontSizeNotSoTall;
                line-height: 44px;
            }
            .documents {
                > li {
                    display: block;
                    &:first-child {
                        display: none;
                    }
                }
            }
            .identifier {
                width: calc(60% - #{$borderDimDesktop});
                margin: 50px 0 0 0;
                li {
                    float: left;
                    line-height: 35px;
                    font-size: $fontSizeMiddle;
                    margin-right: 30px;
                }
            }
            .documents {
                position: absolute;
                right: 0;
                top: 250px;
                display: flex;
                flex-wrap: wrap;
                > li {
                    min-width: calc(100% / 2);
                }
            }
            .prices {
                position: absolute;
                top: 80px;
                right: $borderDimDesktop;
                padding: 0;
                width: calc(40% - #{$borderDimDesktop});
                li {
                    width: calc(50% - 10px);
                    &:last-child {
                        font-size: $fontSizeMiddle;
                        line-height: 25px;
                    }
                }
            }
            notification-box {
                width: 45%;
                margin: 40px 0 0 0 !important;
            }
        }
        section {
            margin: 0;
            padding: $borderDimDesktop $borderDimDesktop 0 $borderDimDesktop;
            tabs {
                tab {
                    &:before {
                        display: none;
                    }
                    &.overview {
                        .columns {
                            width: calc(50% - 100px/2);
                            float: left;
                            &:nth-child(2n) {
                                float: right;
                            }
                        }
                        section {
                            padding: 50px 0 0 0;
                            width:auto;
                            h2 {
                                line-height: 28px;
                                font-family: $mainFontMedium;
                                font-size: $fontSizeBig;
                                text-align: left;
                                margin-left: 0;
                            }
                            &.full-column {
                                width: 100%;
                                .column {
                                    width: calc(50% - 100px/2);
                                    float: left;
                                    &:nth-child(2n) {
                                        float: right;
                                    }
                                }
                            }
                            &.categorization,
                            &.static-data {
                                td {
                                    width: 40%;
                                    &:last-child {
                                        width: 60%;
                                    }
                                    span {
                                        white-space: normal;
                                    }
                                }
                            }
                        }
                    }
                    section {
                        padding: 0;
                    }
                }
                nav {
                    ul {
                        li {
                            flex-grow: 0;
                        }
                    }
                }
            }
            table {
                margin: 30px 0 0 0;
                &.column-count-4 {
                    th {
                        width: calc(100vw / 4);
                    }
                }
                &.column-count-5 {
                    th {
                        width: calc(100vw / 5);
                    }
                }
                &.column-count-6 {
                    th {
                        width: calc(100vw / 6);
                    }
                }
                &.column-count-7 {
                    th {
                        width: calc(100vw / 7);
                    }
                }
                &.column-count-8 {
                    th {
                        width: calc(100vw / 8);
                    }
                }
            }
            simple-table {
                table {
                    tbody {
                        tr {
                            td {
                                width: 50%;
                                background: none;
                                text-align: right;
                                &:first-child {
                                    text-align: left;
                                }
                                span {
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                }
            }
        }
        > section {
            padding-top: 80px;
        }

        flyout-documents {
            position: absolute;
            z-index: 10;
            top: 85px;
            &:before {
                left: 38px;
            }
        }

        div.label-prices-indicative {
            margin-left: 0;
            font-family: $mainFont;
            font-size: 14px;
            line-height: 22px;
        }

        .keyfigures section {
            h2 {
                font-size: $fontSizeBigger;
                color: $textColorNearDark;
                line-height: 32px;
                font-family: $mainFontLight;
                padding-top: 50px;
            }
        }
    }
    table thead tr.trigger-level-column th span em {
        padding-right: 0;
        padding-left: 10px;
    }
}
