/*------------------------------------*\
    #PRODUCT-FILTER-DROPDOWN
\*------------------------------------*/

product-filter-dropdown {
    .list {
        .list-wrapper {
            ul {
                margin: 5px 0 30px 0;
                padding: 0 $filterPadding;
                width: 100%;
                li {
                    border-bottom: 1px solid $backgroundColorNotSoDark;
                    padding: 10px 0 6px 0;
                }
            }
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    product-filter-dropdown {
        .list {
            .list-wrapper {
                ul {
                    padding: 0 $borderDimTablet;
                }
            }
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    product-filter-dropdown {
        .list {
            border-radius: 0 0 4px 4px;
            width: calc(25% - 25px);
            left: calc(50% - 10px);
            &:before {
                left: 0;
                width: 100%;
            }
            .list-wrapper {
                margin: 0;
                min-height: 0;
                ul {
                    margin-bottom: 12px;
                    padding: 0 $filterPadding;
                    li {
                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }
}