/*------------------------------------*\
    #TABS-DYNAMIC
\*------------------------------------*/

tabs-dynamic {
    @extend tabs;
    
    > h3 {
        margin-bottom: $paddingDimMobile;
    }
}
@include mediaQueryMobile {
    tabs-dynamic {
        nav ul {
            justify-content: left;
            overflow-x: hidden;
            overflow-y: hidden;
            li+li {
                margin-left: 2px;
            }
        }
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    tabs-dynamic {
        > h3 {
            margin-bottom: $paddingDimTablet;
        }
        
        nav {
            ul {
                li {
                    flex-grow: 1;
                }
            }
        }
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    tabs-dynamic {
        > h3 {
            margin-bottom: $paddingDimDesktop;
        }
        
        nav {
            ul {
                li {
                    flex-grow: 0;
                }
            }
        }
    }
}