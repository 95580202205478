/*------------------------------------*\
    CHARTCOLORS
\*------------------------------------*/

$colors: ( 1: #E2B224, 2: #96C80F, 3: #4FA645, 4: #25529C, 5: #941E5B);
@function color($key) {
    @if map-has-key($colors, $key) {
        @return map-get($colors, $key);
    }
    @warn "Unknown `#{$key}` in $colors.";
    @return null;
}


/*------------------------------------*\
    #INSTRUMENT-CHART
\*------------------------------------*/

instrument-chart {
    display: block;
    text-align: center;
    dropdown-box {
        display: inline-block;
        cursor: pointer;
        margin: 20px 0 40px 0;
        position: relative;
        height: 50px;
        width: 50%;
        ul {
            background: $backgroundColorMoreBright;
            border-radius: 4px;
            position: absolute;
            width: 100%;
            z-index: 1;
            &:after {
                content: '';
                border-bottom: 2px solid $highlightColor;
                position: absolute;
                width: calc(100% - 6px);
                margin: 0 3px;
                left: 0;
                bottom: 0;
            }
            li {
                color: $textColorDark;
                font-size: $fontSizeMiddle;
                height: 50px;
                line-height: 50px;
                padding: 0 15px;
                &:hover,
                &.selected {
                    color: $highlightColor;
                }
                &.label {
                    &:before {
                        content: '\e901';
                        font-family: $iconFont;
                        font-size: 12px;
                        transform: rotate(90deg);
                        position: absolute;
                        right: 10px;
                    }
                }
            }
        }
    }
    .timespan-select {
        width: 50%;
        margin: 30px 0 40px 0;
    }
    .timespans {
        display: flex;
        margin: 40px 0;
        li {
            margin: 0 20px 0 2px;
            button {
                background: $backgroundColorWhite;
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
                color: $textColorShaded;
                font-size: $fontSizeMiddle;
                font-family: $mainFont;
                height: 50px;
                padding: 0 30px;
                line-height: 50px;
                &:hover {
                    background: $buttonMouserColor;
                }
                &.active {
                    background: $highlightColor;
                    color: $textColorWhite;
                }
            }
        }
    }
    chart {
        display: block;
        width: 100%;
        height: 400px;
        position: relative;
        &.icon-loading:before {
            background: rgba(255, 255, 255, 0.5);
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            border-radius: 0;
            margin: 0;
        }
    }
    .benchmarks {
        margin: 40px $borderDimMobile 20px $borderDimMobile;
        li {
            margin: 0 0 20px 0;
            text-align: left;
            button {
                height: 22px;
                color: $textColorShaded;
                font-family: $mainFont;
                font-size: $fontSizeMiddle;
                line-height: 22px;
                padding: 0;
                position: relative;
                width: auto;
                white-space: nowrap;
                &:before {
                    content: '';
                    background: #A4A4A4;
                    border-radius: 10px;
                    display: inline-block;
                    height: 14px;
                    width: 36px;
                    margin: 5px 15px 0 0;
                }
                &:after {
                    content: '';
                    background: $backgroundColorShaded;
                    border-radius: 20px;
                    display: inline-block;
                    height: 20px;
                    width: 20px;
                    margin: 0 15px 0 0;
                    position: absolute;
                    top: 2px;
                    left: 0;
                    transition: all 120ms ease-out;
                    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
                }
                &:not(.active):before {
                    background: #c6c5c5;
                }
                &:not(.active):after {
                    background: #f1f1f1;
                }
                &.active {
                    &:after {
                        transform: translate(16px, 0);
                    }
                }
            }
            a {
                color: $highlightColor;
                font-family: $mainFont;
                font-size: $fontSizeRegular;
                line-height: 25px;
            }
        }
        /* Dynamic colors for benchmark button */
        @for $i from 0 through 5 {
            li.colored:nth-child(#{$i}) {
                button {
                    &:before {
                        background: map-get($colors, $i);
                        opacity: 0.7;
                    }
                    &:after {
                        background: map-get($colors, $i);
                    }
                    &:not(.active):before {
                        background: #c6c5c5;
                    }
                    &:not(.active):after {
                        background: #f1f1f1;
                    }
                }
            }
        }
    }
    footer {
        margin: 0 $borderDimMobile;
        color: $textColorDark;
        font-family: $mainFont;
        font-size: $fontSizeRegular;
        line-height: 24px;
        text-align: left;
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    instrument-chart {
        padding: 0 $borderDimTablet;
        
        .benchmarks {
            margin: 40px 0 20px 0;
        }
        footer {
            margin: 0;
        }
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    instrument-chart {
        padding: 0;
        
        .benchmarks {
            display: flex;
            flex-wrap: wrap;
            margin: 40px 0 20px 0;
            li {
                margin: 0 40px 20px 0;
                &:last-child {
                    flex: none;
                    width: 100%;
                }
                button {
                    height: 25px;
                    font-size: 21px;
                    line-height: 25px;
                }
                a {
                    font-size: $fontSizeMiddle;
                    line-height: 25px;
                    margin: 0 0 0 20px;
                }
            }
        }
        footer {
            margin: 0;
            font-size: $fontSizeMiddle;
            line-height: 24px;
        }
    }
}