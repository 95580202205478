.message-container, .cms-message-container {
    position: relative;
    padding: 20px 20px 20px 50px;
    color: #333333;
    margin: 30px 16px;
    &.success {
        background-color: $infoBoxSuccessBackground;
        .message-icon i, .message-icon span {
            color: $infoBoxSuccess;
        }
    }
    &.error {
        background-color: $infoBoxErrorBackground;
        .message-icon i, .message-icon span {
            color: $infoBoxError;
        }
    }
    &.info {
        background-color: $backgroundColorMoreBright;
        .message-icon i, .message-icon span {
            color: $textColorBlack;
        }
    }
    .message-title {
        padding-bottom: 5px;
        font-family: $mainFontBold;
        font-size: 18px;
        text-align: left;
    }
    .message-link {
        padding-top: 12px;
        a {
            font-family: $mainFontMedium;
            color: #333333;
        }
        .iconfont {
            width: 13px;
            font-size: 13px;
            padding-right: 6px;
        }
        &.right {
            text-align: right;
        }
    }
    .message-body {
        font-family: $mainFont;
        font-size: 18px;
        font-weight: 300;
        line-height: 25px;
        text-align: left;
        b {
            font-family: $mainFontBold;
        }
    }
    .message-icon {
        position: absolute;
        left: 20px;
        top: 5px;
        i, span {
            color: $infoBoxSuccess;
            &:before {
                font-size: 20px;
            }
        }
    }
}
.cms-message-container {
    .message-icon {
        top: 19px;
    }
}