/*------------------------------------*\
    #SEARCH
\*------------------------------------*/

.show-menu search {
    display: block;
}

search {
    display: none;
    position: absolute;
    top: 100px;
    right: 0;
    width: calc(100vw - 50px);
    padding: 5px $borderDimMobile;
    box-sizing: border-box;
    z-index: 25;
    &.active {
        .close {
            display: inherit;
        }
    }
    &.icon-loading {
        &:before {
            top: 25px;
            left: 37px;
            background: $backgroundColorWhite;
        }
        &:after {
            top: 32px;
            left: 44px;
            height: 15px;
            width: 15px;
        }
    }
    form {
        &.data-available {
            box-shadow: 0 2px 6px 0 rgba(0,0,0,0.25);
            input,
            .icon-search {
                border-color: transparent;
            }
        }
        fieldset {
            .icon-search {
                background: $backgroundColorWhite;
                border: 1px solid $backgroundColorBright;
                border-right: none;
                float: left;
                color: #666666;
                &:before {
                    display: block;
                    width: 30px;
                    height: 36px;
                    line-height: 37px;
                    text-align: right;
                    font-size: 17px;
                }
            }
            input {
                padding: 0 10px;
                background: $backgroundColorWhite;
                border: 1px solid #E3E3E3;
                border-left: none;
                color: $textColorDark;
                height: 38px;
                line-height: auto;
                float: left;
                width: calc(100% - 32px);
                border-radius: 0;
            }
            .close {
                background: $backgroundColorWhite;
                display: none;
                position: absolute;
                right: 2px;
                top: 1px;
                height: 36px;
                width: 38px;
                line-height: 36px;
                text-align: center;
                padding: 0;
                &:before {
                    content: '\e906';
                    font-family: $iconFont;
                    font-size: 12px;
                    color: $textColorBlack;
                }
            }
        }
        .results {
            background: $backgroundColorWhite;
            box-sizing: border-box;
            overflow-y: auto;
            max-height: calc(100vh - 220px);
            padding: 0 $borderDimMobile $borderDimMobile $borderDimMobile;
            ul {
                padding: 20px 0 0 0;
                li {
                    a {
                        color: $textColorBlack;
                        font-size: $fontSizeRegular;
                        font-family: $mainFontLight;
                        line-height: 20px;
                        padding: 5px 0;
                        display: block;
                        &.active,
                        &:hover {
                            background-color: $buttonMouserColor;
                            color: $highlightColor;
                            text-decoration: none;
                        }
                    }
                    &.label {
                        color: $textColorDark;
                        font-size: $fontSizeMiddle;
                        font-family: $mainFontMedium;
                        line-height: 22px;
                        margin: 0 0 5px 0;
                        &.sub-label {
                            margin: 20px 0 0 0;
                        }
                    }
                }
            }
        }
    }
}


/*------------------------------------*\
  #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    search {
        width: 50%;
        padding: 5px $borderDimTablet;
    }
}


/*------------------------------------*\
  #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    search {
        display: block;
        background: $headerColor;
        width: 232px;
        top: 40px;
        right: auto;
        left: calc(#{$borderDimDesktop} + 700px);
        padding: 22px 0 17px 0;
        
        /* Hack for IE9. */
        padding-bottom: 18px\0;
        
        z-index: 1;
        &.icon-loading {
            &:before {
                top: 40px;
                left: 20px;
                background: $backgroundColorWhite;
            }
            &:after {
                top: 45px;
                left: 27px;
                height: 15px;
                width: 15px;
            }
        }
        &.active {
            transform: translate(-408px);
            width: 640px;
            z-index: 200;
        }
        form {
            fieldset {
                .icon-search {
                    &:before {
                        height: 34px;
                        line-height: 35px;
                    }
                }
                input {
                    height: 36px;
                }
                .close {
                    height: 34px;
                    line-height: 34px;
                }
            }
            .results {
                box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
                border-bottom: 20px solid $backgroundColorWhite;
                position: absolute;
                top: 57px;
                left: 0;
                width: 639px;
                height: auto;
                max-height: 400px;
                padding: 0 38px;
            }
        }
    }
    
    /* Hack for > IE9. */
    @media screen and (-ms-high-contrast:active), (-ms-high-contrast:none) {
        search {
            padding-bottom: 18px;
        }
    }
    
    /* Hack for Firefox. */
    @-moz-document url-prefix() {
        search {
            padding-bottom: 18px;
        }
    }
}