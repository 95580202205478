/*------------------------------------*\
    #ADDITIONAL-BAR
\*------------------------------------*/

additional-bar {
    ul {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 2px;
        width: 100%;


        /**
         * Sticky variant.
         */
        &.sticky {
            opacity: 1;
            position: fixed;
            z-index: 10000;
            bottom: 0;
            margin-bottom: 0;
            margin-left: -$borderDimMobile;
            padding-right: $borderDimMobile;
            transform: translate3d(0, 0, 0);
            -moz-transition: all 0.2s linear;
            -webkit-transition: all 0.2s linear;
            -o-transition: all 0.2s linear;
            transition: all 0.2s linear;

            &.hide {
                opacity: 0;
                transform: translate3d(0, 30px, 0);
            }
        }

        li {
            .button-primary {
                padding: 0 8px;
                height: 28px;
                line-height: 28px;
                border-radius: 0 0 3px 3px;

                i {
                    margin-right: 0;
                }
            }
        }
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    additional-bar {
        ul {
            margin-bottom: 30px;

            /**
             * Sticky variant.
             */
            &.sticky {
                margin-left: -$borderDimTablet;
                padding-right: $borderDimTablet;
            }

            li {
                .button-primary {
                    padding: 0 15px;
                    height: 40px;
                    line-height: 40px;

                    i {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    additional-bar {
        ul {


            /**
             * Sticky variant.
             */
            &.sticky {
                margin-left: -$borderDimDesktop;
                padding-right: $borderDimDesktop;
                max-width: 1280px;
            }
        }
    }
}