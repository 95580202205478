/*------------------------------------*\
    #OPPORTUNITIES-SECONDARY-MARKET
\*------------------------------------*/

.opportunities-secondary-market {
    &.icon-loading {
        &:before {
            background: $backgroundColorWhite;
            height: 100%;
            width: 100%;
            top: 45px;
            left: 0;
            border-radius: 0;
            margin: 0;
            position: fixed;
        }
        &:after {
            top: 350px;
            position: fixed;
        }
    }
    display: block;
    
    /**
     * Header.
     */
    header {
        /**
         * Advice box.
         */
        advice-box {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 40px;
            padding: 20px 40px 30px 40px;
            background: #F5F6F8;
            h4 {
                margin: 0;
                width: 100%;
                line-height: 25px;
            }
            h4:after {
                content: ":";
            }
            .content {
                margin: 10px 0 0 0;
                width: 100%;
                line-height: 25px;
                font-size: $fontSizeMiddle;
                color: #262626;
            }
            /**
             * Criteria group.
             */
            criteria-group {
                +criteria-group:before {
                    content: "/ ";
                }
                ul {
                    display: inline;
                    li {
                        display: inline;
                    }
                    li+li:before {
                        content: ", ";
                    }
                }
            }
            .button {
                margin: 10px 0 0 0;
            }
        }
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    .opportunities-secondary-market {
        header {
            advice-box {
                margin: 30px $borderDimTablet 0 $borderDimTablet;
            }
        }
        section {
            h2 {
                font-size: $fontSizeTall;
                line-height: 54px;
            }
        }
        
        flyout-documents {
            position: absolute;
            z-index: 10;
            top: 490px;
            right: 6px;
        }
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    .opportunities-secondary-market {
        &.icon-loading {
            &:before {
                top: 245px;
                position: absolute;
                height: calc(100% - 245px);
            }
            &:after {
                top: 65vh;
                position: absolute;
            }
        }
        
        margin-top: 0;
        
        header {
            advice-box {
                position: relative;
                margin: 40px $borderDimDesktop 0 $borderDimDesktop;
                
                h4 {
                    width: 75%;
                }
                
                .content {
                    margin-right: 10px;
                    width: 75%;
                }
                
                .button {
                    position: absolute;
                    top: 50%;
                    right: 40px;
                    margin-top: -25px;
                }
            }
        }
    }
}