/*------------------------------------*\
    #PRODUCT-DETAIL-PATTERN
\*------------------------------------*/

product-detail-pattern-mobile {
    ul.pattern {
        margin: 50px 0 0 0;
        padding-left:50px;
        overflow: hidden;
        width: 1400%;
        transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 500ms;
        li {
            box-sizing: border-box;
            float: left;
            width: calc(100vw - 100px);
            height: 138px;
            border: 2px solid $textColorMain;
            margin: 0;
            padding: 25px $paddingDimMobile;
            transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 500ms;
            transform: scale(0.8, 0.8);
            opacity: 0.5;
            &.active {
                transform: scale(1, 1);
                opacity: 1;
            }
            .value {
                color: $textColorBlack;
                display: block;
                font-size: $fontSizeBig;
                line-height: 28px;
                margin: 0 0 5px 0;
                min-height: 28px;
                em {
                    font-style: normal;
                }
            }
            .text {
                color: $textColorBlack;
                display: block;
                font-size: $fontSizeMiddle;
                line-height: 25px;
            }
            
        }
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    product-detail-pattern-mobile {
        ul.pattern {
            padding-left:90px;
            li {
                box-sizing: border-box;
                float: left;
                width: calc(100vw - 180px);
            }
        }
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    product-detail-pattern {
        ul.pattern {
            padding: 0;
            margin: 50px 0 0 0;
            display: flex;
            flex-wrap: wrap;
            width: auto;
            li {
                box-sizing: border-box;
                border: 2px solid $textColorMain;
                float: left;
                height: 138px;
                width: calc(100%/4 - 15px);
                margin: 0 $paddingDimMobile $paddingDimMobile 0;
                padding: 25px 20px;
                &:nth-child(2n) {
                    margin: 0 $paddingDimMobile $paddingDimMobile 0;
                }
                &:nth-child(4n) {
                    margin-right: 0;
                }
                .value {
                    color: $textColorBlack;
                    display: block;
                    font-size: $fontSizeBig;
                    line-height: 28px;
                    margin: 0 0 5px 0;
                    min-height: 28px;
                    em {
                        font-style: normal;
                    }
                }
                .text {
                    color: $textColorBlack;
                    display: block;
                    font-size: $fontSizeMiddle;
                    line-height: 25px;
                }
            }
        }
    }
}