/*------------------------------------*\
    #INSTRUMENT-TABLE-PAGING
\*------------------------------------*/

instrument-table-paging {
    display: block;
    text-align: center;
    ul {
        display: flex;
        justify-content: center;
        margin: 40px 0;
        li {
            &.ng-hide:not(.ng-hide-animate) {
                display: inherit !important;
                visibility: hidden;
            }
            button {
                border-radius: 50%;
                color: $textColorDark;
                font-family: $mainFont;
                font-size: $fontSizeMiddle;
                line-height: 30px;
                width: 30px;
                height: 30px;
                margin: 0 5px;
                position: relative;
                padding:0;
                &.active,
                &:hover:not(.set-page) {
                    background: $highlightColor;
                    color: $textColorWhite;
                }
                &.set-page {
                    &:before {
                        content: '\e901';
                        font-family: $iconFont;
                        font-size: $fontSizeRegular;
                        color: $textColorDark;
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                    &.previous {
                        &:before {
                            transform: rotate(180deg);
                            right: auto;
                            left: 0;
                        }
                    }
                }
            }
        }
    }
}