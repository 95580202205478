/*------------------------------------*\
    #MEDIA-QUERIES
\*------------------------------------*/

@mixin mediaQueryMobile {
    @media all and (max-width: 479px) {
        @content;
    }
}

@mixin mediaQueryMedium {
    @media all and (min-width: 480px) {
        @content;
    }
}

@mixin mediaQueryMediumRegular {
    @media all and (min-width: 480px) and (max-width: 959px) {
        @content;
    }
}

@mixin mediaQueryRegular {
    @media all and (min-width: 960px) {
        @content;
    }
}

/*------------------------------------*\
    #MEASUREMENTS
\*------------------------------------*/

$borderDimMobile: 16px;
$borderDimTablet: 24px;
$borderDimDesktop: 50px;

$paddingDimMobile: 20px;
$paddingDimTablet: 30px;
$paddingDimDesktop: 50px;

$tabDimMobile: 30px;
$tabDimTablet: 30px;
$tabDimDesktop: 50px;

$maxWidth: 1280px;
$mainHeaderSizeMobile: 47px;
$mainHeaderSizeRegular: 40px;
$footerLightHeightRegular: 372px;
$footerDarkHeightRegular: 429px;

$firstColumnMobile: 120px;
$firstColumnTablet: 30%;