/*------------------------------------*\
    #PRODUCT-FILTER-GROUP
\*------------------------------------*/

product-filter-group {
    .list {
        .list-wrapper {
            overflow-y: auto;
            height: calc(100vh - 340px);
        }
    }
}

.ios product-filter-group .list .list-wrapper>ul:last-child {
    margin-bottom: 100px;
}

/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    product-filter-group {}
}

/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    product-filter-group {
        .list {
            .list-wrapper {
                height: auto;
            }
        }
    }
}