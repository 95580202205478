/*------------------------------------*\
    #INSTRUMENT-TABLE
\*------------------------------------*/

instrument-table {
    display: block;
    table {
        td {
            cursor: pointer;
            em {
                cursor: pointer;
                font-style: normal;
                display: table-cell;
            }
            span {
                cursor: pointer;
            }
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    instrument-table {}
}

/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    instrument-table {
        table {
            display: table;
            thead {
                tr {
                    th {
                        hr {
                            border-color: #a2a2a2;
                            border-collapse: collapse;
                            border-style: solid;
                        }
                        span {
                            font-size: $fontSizeRegular;
                            &:hover:not(.not-sortable) {
                                cursor: pointer;
                                em {
                                    background: $backgroundColorTableShaded;
                                }
                            }
                            &.active,
                            &.initial-active {
                                em {
                                    background: $backgroundColorTableShaded;
                                    &:after {
                                        content: '\e912';
                                        color: $highlightColor;
                                        font-family: $iconFont;
                                        font-size: 5px;
                                        position: absolute;
                                        right: 7px;
                                        top: calc(50% - 10px);
                                    }
                                }
                            }
                            em {
                                padding: 7px 20px 7px 5px;
                                line-height: 18px;
                                border-radius: 20px;
                            }
                            /* Special fix for #65609 */
                            &[order-by=subscriptionPeriodEndDate] {
                                min-width: 160px;
                            }
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        padding: 10px 5px;
                        hr {
                            border-color: #E3E3E3;
                            border-style: solid;
                            border-width: 0.5px;
                            border-collapse: collapse;
                        }
                        span {
                            font-size: $fontSizeRegular;
                        }
                        i span.path1, i span.path2 {
                            font-size: $fontSizeNotSoTiny;
                        }
                        &.menu {
                            width: 25px;
                        }
                        .icon {
                            position: relative;
                        }
                        .flyout {
                            display: none;
                            width: auto;
                            min-width: 50px;
                            border-radius: 4px;
                            background: $backgroundColorWhite;
                            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
                            z-index: 1000;
                            padding: 13px 15px;
                            line-height: 18px;
                            position: absolute;
                            text-align: center;
                            left: -30px;
                            top: 40px;
                            white-space: nowrap;
                            &:before {
                                text-shadow: 0px 1px rgba(0, 0, 0, 0.14);
                                color: $backgroundColorWhite;
                                font-family: $iconFont;
                                font-size: 15px;
                                position: absolute;
                                content: '\e912';
                                left: 25px;
                                top: -15px;
                                display: block;
                                transform: rotate(180deg);
                            }
                            &.align-right {
                                &:before {
                                    left: auto;
                                    right: 50px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.basket-components {
            table {
                tbody {
                   tr td:first-child{
                       max-width: 250px;
                       white-space: normal !important;
                   }
                }
            }
        }
    }

    /* Subscription products inner instruments table hover text fix  */
    subscription-products {
            instrument-table {
                table {
                    tbody {
                        tr {
                            td.maturityDate {
                                .flyout {
                                    left: -260px;

                                    &:before{
                                        left: 255px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
    }
}