/*------------------------------------*\
    #PRODUCT-DETAIL-PATTERN
\*------------------------------------*/

subscription-products-mobile {
    ul.pattern {
        margin: 30px 0 0 0;
        padding: 5px 5px 5px 50px;
        overflow: hidden;
        width: 1400%;
        transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 500ms;
        li {
            box-sizing: border-box;
            float: left;
            width: calc(100vw - 100px);
            min-height: 220px;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
            margin: 0;
            padding: 7px 10px;
            transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 500ms;
            transform: scale(0.88, 0.88);
            text-align: left;
            opacity: 0.5;
            &.active {
                transform: scale(1, 1);
                opacity: 1;
            }
            .identifier {
                color: $textColorDark;
                font-family: $mainFont;
                font-size: $fontSizeNotSoTiny;
                line-height: 18px;
            }
            .name {
                color: $textColorDark;
                display: block;
                font-size: $fontSizeRegular;
                line-height: 24px;
                margin: 30px 0;
                min-height: 70px;
            }
            .currency {
                font-size: $fontSizeNotSoTiny;
                float: right;
            }
            .subscription-period {
                color: $textColorDark;
                font-family: $mainFont;
                font-size: $fontSizeNotSoTiny;
                line-height: 18px;
            }
            .label {
                font-size: $fontSizeNotSoTiny;
                &:after {
                    content: ': ';
                    margin: 0 2px 0 0;
                }
            }
            span.icon {
                margin: 0 3px;
            }
            .documents {
                background-color: #F5F6F8;
                color: $textColorDark;
                font-family: $mainFont;
                font-size: $fontSizeNotSoTiny;
                line-height: 18px;
                margin: 18px 0 0 0;
                padding: 0px 10px;
                &.active {
                    display: block;
                    button {
                        &:before {
                            transform: rotate(-90deg);
                        }
                    }
                    .documents-extended {
                        display: block;
                        padding-bottom: 7px;
                    }
                }
                button {
                    height: 18px;
                    background-color: #F5F6F8;
                    position: relative;
                    &:before {
                        content: "\e901";
                        display: inline-block;
                        font-family: $iconFont;
                        font-size: 12px;
                        top: -15px;
                        position: absolute;
                        text-align: center;
                        transform: rotate(90deg);
                    }
                }
                .documents-extended {
                    display: none;
                    margin: 7px 0 0 0;
                    .product-link {
                        display: block;
                        margin: 0 0 20px 0;
                        height: 24px;
                        color: $highlightColor;
                        line-height: 24px;
                        font-size: $fontSizeMiddle;
                    }
                    .label {
                        display: block;
                        font-size: $fontSizeMiddle;
                        font-family: $mainFontLight;
                        margin: 0 0 5px 0;
                    }
                    .no-results {
                        display: block;
                        font-size: $fontSizeMiddle;
                        font-family: $mainFontLight;
                        margin: 0 0 5px 0;
                    }
                    a:not(.product-link) {
                        color: $highlightColor;
                        margin: 0 3px 0 0;
                        &:after {
                            content: ' |';
                            color: $textColorDark;
                        }
                        &:last-child {
                            &:after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    subscription-products-mobile {
        ul.pattern {
            li+li {
                margin-left: -30px;
            }
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {}