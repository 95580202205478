instrument-table-range-color {
    position: relative;
    .flyout {
        left: -15px !important;
        p {
            em {
                color: $textColorNotSoDark;
                display: block;
                &:after {
                    content: ', ';
                }
                &:last-child {
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
}