toggle-button {
	display: inline-block;
	button {
		position: relative;
		padding: 0;
		margin: 0;
		height: 30px;
		line-height: 30px;
		&:before {
			content: '';
			background-color: #9b9b9b;
			border-radius: 10px;
			display: inline-block;
			height: 21px;
			width: 48px;
			margin: 5px 15px 0 2px;
		}
		&:after {
			content: '\e906';
			font-family: $iconFont !important;
			color: #A3A3A3;
			font-size: 10px;

			background-color: #F1F1F1;
			border-radius: 20px;
			display: inline-block;
			height: 28px;
			width: 28px;
			margin: 0 15px 0 0;
			position: absolute;
			top: 1px;
			left: 0;
			transition: all 120ms ease-out;
			box-shadow: 0 2px 6px 0 rgba(0,0,0,.25);
		}
		&.disabled {
			cursor: not-allowed;
			&:before {
				content: '\e923';
				font-family: $iconFont !important;
				color: #FFF;
				font-size: 10px;
				text-align: right;
				box-sizing: border-box;
				padding-right: 7px;
				line-height: 22px;
			}
		}
		&.active{
			&:after {
				content: '\e900';
				font-family: $iconFont !important;
				color: #FFF;
				font-size: 10px;
				transform: translate(28px,0);
				background-color: #8BC34A;
			}
			&:before {
				background-color: #8BC34A;
				opacity: .7;
			}
		}
		&.active.disabled {
			&:before {
				content: '\e923';
				font-family: $iconFont !important;
				text-align: left;
				padding-left: 10px;
			}
		}
	}
}