/*------------------------------------*\
    #FOR FULLSCREEN WIDTH ELEMENTS
\*------------------------------------*/

@media all and (min-width: 1280px) {
    main-header {
        &:before {
            content: '';
            background: $backgroundColorWhite;
            height: $mainHeaderSizeRegular;
            width: 200vw;
            display: block;
            position: absolute;
            left: -100vw;
        }
        &:after {
            content: '';
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
            background: $headerColor;
            height: 80px;
            width: 200vw;
            display: block;
            position: absolute;
            left: -100vw;
            top: 40px;
        }
        .dark {
            box-shadow: none !important;
        }
    }
    main-footer {
        footer {
            &:before {
                content: '';
                background: $headerColor;
                height: $footerLightHeightRegular;
                width: 200vw;
                display: block;
                position: absolute;
                left: -100vw;
            }
            &:after {
                content: '';
                box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
                background: $backgroundColorDark;
                height: $footerDarkHeightRegular;
                width: 200vw;
                display: block;
                position: absolute;
                left: -100vw;
                bottom: 0;
            }
        }
    }
}