/*------------------------------------*\
    #COLORS
\*------------------------------------*/

$backgroundColorMoreBright: #F5F6F8;
$backgroundColorBright: #E3E3E3;
$backgroundColorVeryBright: #FAFAFA;
$backgroundColorDark: #333;
$backgroundColorNotSoDark: #666;
$backgroundColorNotSoVeryDark: #6B6B6B;
$backgroundColorNotSoVeryVeryDark: #C4C4C4;
$backgroundColorShaded: #4A4A4A;
$backgroundColorWhite: #FFF;
$backgroundColorTableShaded:#F5F6F8;

$linkColor: #333;
$linkColor2: #333333;
$buttonMouserColor: #FAFAFA;

$highlightColor: #007DB2;
$highlightColorDark: #9b9b9b;
$highlightColorError: #D74B32;
$highlightColorBright: #FAFAFA;
$headerColor: #003764;

$textColorBlack: #000;
$textColorNotSoBlack: #535353;
$textColorBlue: #0D69A2;
$textColorBright: #E3E3E3;
$textColorDark: #333;
$textColorNearDark: #333333;
$textColorNotSoDark: #666;
$textColorMain: #9b9b9b;
$textColorShaded: #262626;
$textColorWhite: #FFF;
$textColorError: #D74B32;
$textColorDisabled: #898989;

// Color ranges for yield matrix
$rangeColor8: #003764;
$rangeColor7: #0093D1;
$rangeColor6: #46A0DC;
$rangeColor5: #69AFE1;
$rangeColor4: #82BEE6;
$rangeColor3: #96C8F0;
$rangeColor2: rgb(180,215,250);
$rangeColor1: #D2E6FA;
$rangeColor0: #E6F0FF;

//Infobox
$infoBoxSuccess: #8BC34A;
$infoBoxSuccessBackground: #F3F9EC;
$infoBoxError: #D74B32;
$infoBoxErrorBackground: #FBEDEA;