/*------------------------------------*\
    #DOT-NAVIGATION
\*------------------------------------*/

footer.dot-navigation {
    ul {
        display: flex;
        justify-content: center;
        margin: 30px 0 0 0;
        li {
            background: $backgroundColorWhite;
            border: 1px solid $backgroundColorBright;
            border-radius: 50%;
            cursor: pointer;
            height: 16px;
            width: 16px;
            margin: 0 10px;
            &.active {
                border: none;
                background: $highlightColor;
            }
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {}

/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    footer.dot-navigation {
        display: none;
    }
}