/*------------------------------------*\
    #TABLES
\*------------------------------------*/

// General table style
table:not(.no-style) {
    border: none;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    margin: 20px 0 0 0;
    &.asc .sorting,
    &.initial-asc .sorting {
        & > .sorting-wrapper:after,
        &:after {
            transform: rotate(180deg);
        }
    }
    thead {
        tr {
            th {
                border-bottom: 2px solid $backgroundColorNotSoDark;
                color: $textColorDark;
                text-align: left;
                vertical-align: middle;
                font-family: $mainFontMedium;
                font-weight: normal;
                padding: 7px 10px;
                span {
                    em {
                        font-style: normal;
                    }
                }
            }
        }
    }
    tbody {
        tr {
            &:first-child {
                td {
                    border-top: 1px solid $backgroundColorNotSoDark;
                }
            }
            td {
                border-bottom: 1px solid $backgroundColorNotSoDark;
                color: $textColorNotSoDark;
                vertical-align: middle;
                line-height: 18px;
                padding: 7px 10px;
                height: 18px;
                .icon {
                    margin-left: 5px;
                    display: inline;
                }
                &.color-0 {
                    background: $rangeColor0;
                }
                &.color-1 {
                    background: $rangeColor1;
                    color: $textColorBlack;
                }
                &.color-2 {
                    background: $rangeColor2;
                    color: $textColorBlack;
                }
                &.color-3 {
                    background: $rangeColor3;
                    color: $textColorBlack;
                }
                &.color-4 {
                    background: $rangeColor4;
                    color: $textColorBlack;
                }
                &.color-5 {
                    background: $rangeColor5;
                    color: $textColorBlack;
                }
                &.color-6 {
                    background: $rangeColor6;
                    color: $textColorBlack;
                }
                &.color-7 {
                    background: $rangeColor7;
                    color: $textColorBlack;
                }
                &.color-8 {
                    background: $rangeColor8;
                    color: $textColorWhite;
                }
                ins {
                    font-style: normal;
                    font-family: $mainFont;
                    text-decoration: none;
                    &.currency {
                        &:before {
                            content: '';
                            margin: 0 3px 0 0;
                            display: inline-block;
                        }
                    }
                }
                &.currency,
                &.maturityDate,
                &.subscriptionPeriodEndDate {
                    white-space: nowrap;
                }
            }
        }
    }
}

.table-footer {
    color: $textColorDark;
    font-family: $mainFontLight;
    font-size: $fontSizeRegular;
    line-height: 24px;
    margin: 22px 0 0 0;
    padding: 0 $borderDimMobile $borderDimMobile $borderDimMobile;
    p {
        line-height: 24px;
        text-align: left;
    }
    &.align-left {
        text-align: left;
    }
}

footer.table-footer {
    ul {
        list-style-type: disc;
        padding-left: 18px;
    }
    ol {
        list-style: decimal inside;
    }
}

// Mobile only table
table-mobile,
simple-table {
    table:not(.no-style) {
        thead {
            tr {
                th {
                    background: $backgroundColorTableShaded;
                    font-size: $fontSizeTiny;
                    line-height: 16px;
                    padding: 0;
                    color: $textColorMain;
                    text-align: center;
                }
            }
        }
        tbody {
            tr {
                td {
                    text-align: center;
                    &:first-child {
                        color: $textColorDark;
                        font-family: $mainFontMedium;
                    }
                    em {
                        font-style: normal;
                    }
                    &.menu {
                        span {
                            display: inline-block;
                            line-height: 17px;
                            height: 17px;
                            button {
                                line-height: 17px;
                                height: 17px;
                                padding: 0;
                                width: 25px;
                                &:before {
                                    content: "\e913";
                                    color: $highlightColor;
                                    font-size: 12px;
                                    font-family: $iconFont;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

table-mobile {
    table:not(.no-style) {
        tbody {
            tr {
                td {
                    &:first-child:not([class*=color-]) {
                        background: $backgroundColorTableShaded;
                    }
                }
            }
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    table:not(.no-style),
    simple-table table {
        margin: 50px 0 0 0;
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    table:not(.no-style),
    simple-table table:not(.no-style) {
        margin: 50px 0 0 0;
        thead {
            tr {
                th {
                    padding: 3px 0;
                    line-height: 21px;
                    span {
                        padding: 0;
                        display: table-cell;
                        user-select: none;
                        height: 59px;
                        vertical-align: middle;
                        em {
                            border-radius: 32px;
                            box-sizing: border-box;
                            display: block;
                            line-height: 22px;
                            padding: 7px 35px 7px 15px;
                            position: relative;
                            width: 100%;
                            user-select: none;
                            -webkit-user-select: none;
                        }
                    }
                    hr {
                        display: block;
                        margin: 0;
                        border-style: inset;
                        border-width: 1px;
                        border-color: $backgroundColorNotSoDark;
                        width: calc(100% - 15px);
                    }
                    &.align-right {
                        text-align: right;
                        span {
                            display: inline;
                            &.active {
                                em {
                                    &:after {
                                        left: 15px;
                                        right: auto;
                                    }
                                }
                            }
                            em {
                                padding: 7px 15px 7px 35px;
                            }
                        }
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    padding: 10px 15px;
                    line-height: 25px;
                    text-align: left;
                    &.active:not([class*=color-]), /* apply styles to all that do not contain class names such as: color-1,color-2,....color-n */
                    &.initial-active {
                        background-color: $backgroundColorTableShaded;
                    }
                    &.align-right {
                        text-align: right;
                        span {
                            display: inline;
                        }
                    }
                    span {
                        display: block;
                    }
                    span.inline-item {
                        display: inline;
                    }
                    hr {
                        position: relative;
                        left: -15px;
                        width: calc(100% + 15px);
                    }
                    &.menu {
                        span {
                            display: inline-block;
                            line-height: 21px;
                            height: 21px;
                            button {
                                line-height: 25px;
                                height: 25px;
                                padding: 0;
                                width: 17px;
                                position: relative;
                                &:after {
                                    content: "\e913";
                                    color: $highlightColor;
                                    font-size: 14px;
                                    font-family: $iconFont;
                                    position: relative;
                                }
                                &.active,
                                &:hover {
                                    &:before {
                                        background: $backgroundColorMoreBright;
                                        border-radius: 50%;
                                        content: '';
                                        display: block;
                                        height: 38px;
                                        width: 38px;
                                        position: absolute;
                                        left: -10px;
                                        top: -6px;
                                    }
                                }
                            }
                        }
                    }
                    &.currency,
                    &.underlyingNames {
                        button {
                            background: none;
                            position: absolute;
                            z-index: 0;
                            margin: -21px 0 0px 34px;
                            width: 15px;
                            height: 15px;
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
    table.observation-days-list-table {
        thead {
            tr {
                th {
                    span {
                        em {
                            padding: 7px 35px 7px 0px;
                        }
                    }
                    &:nth-child(2) {
                        min-width: 132px;
                    }
                    &:nth-child(3) {
                        min-width: 170px;
                    }
                }
            }
        }
    }
    simple-table table:not(.no-style) {
        margin: 30px 0 0 0;
        tbody {
            tr {
                td {
                    padding: 10px 0;
                }
            }
        }
    }
    .table-footer {
        padding: 0;
        font-size: $fontSizeMiddle;
        line-height: 25px;
        p {
            line-height: 25px;
        }
    }
}