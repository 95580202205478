/*------------------------------------*\
    #SIMPLE-TABLE
\*------------------------------------*/

simple-table {
    table {
        tbody {
            tr {
                td:first-child {
                    width: 120px;
                    span {
                        display: block;
                        text-align: left;
                    }
                }
            }
        }
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    simple-table {
        table {
            tbody {
                tr {
                    td:first-child {
                        width: 30%;
                        span {
                            width:auto;
                        }
                    }
                }
            }
        }
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    simple-table {
        table {
            tbody {
                tr {
                    td:first-child {
                        width: auto;
                        span {
                            width: auto;
                        }
                    }
                }
            }
        }
    }
}