/*------------------------------------*\
    #ICONS-LOGOS
\*------------------------------------*/

i {
    font-style: normal;
    font-family: $iconFont;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -webkit-user-select: none;
    -moz-osx-font-smoothing: grayscale;
}

/**
 * Iconfont is used within the CMS CKEditor.
 * Not possible to render nested elements.
 */

.iconfont {
    position: relative;
    display: inline-block;
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        font-style: normal;
        font-family: $iconFont;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        text-rendering: auto;
        speak: none;
        -webkit-font-smoothing: antialiased;
        -webkit-user-select: none;
        -moz-osx-font-smoothing: grayscale;
    }
    &.icon-pdf {
        padding-left: 18px;
    }
    &.icon-pdf:before {
        content: "\e91b";
        margin-top: -1px;
        font-size: 14px;
    }

    &.icon-check_circle {

    }
    &.icon-check_circle:before {
        content: "\e91c";
    }

    &.icon-arrow_forward:before {
        content: "\e902";
    }

    &.icon-info {

    }
    &.icon-info:before {
        content: "\e921";
    }

    &.icon-warning {

    }
    &.icon-warning:before {
        content: "\e91e";
    }

    &.download-pdf {
        box-sizing: border-box;
        font-family: $mainFontLight;
        font-size: $fontSizeMiddle;
        border-bottom: 1px solid $textColorNotSoDark;
        padding: 40px 45px;
        line-height: 24px;
        width:calc(100% - #{$borderDimMobile});
        position: relative;
        text-align: left;
        &:before {
            content: "\e91b";
            font-size: 29px;
            color: $textColorNotSoDark;
            position: absolute;
            top: calc(50% - 15px);
        }
    }
    &.icon-arrow {
        padding-left: 18px;
    }
    &.icon-arrow:before {
        content: "\e902";
        font-size: 13px;
    }
    &.icon-external {
        padding-left: 18px;
    }
    &.icon-external:before {
        content: "\e920";
        margin-top: -1px;
        font-size: 10px;
    }
}

.icon-call:before {
    content: "\e905";
    display: block;
    font-family: $iconFont;
    width: 30px;
    height: 30px;
    line-height: 30px;
    color: $textColorWhite;
}

.icon-loading:before,
.icon-loading:after {
    content: "";
    display: block;
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 50%;
    margin: -15px 0 0 -15px;
}

#ui-view.icon-loading:before,
#ui-view.icon-loading:after {
    position: fixed;
    top: 143px;
}

.icon-loading:after {
    z-index: 101;
    margin: -17px 0 0 -17px;
    width: 50px;
    height: 50px;
    background: url(../images/icon-loading.svg) no-repeat;
}

@keyframes fadingLoader {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
}

.icon-mail:before {
    content: "\e903";
    display: block;
    font-family: $iconFont;
    width: 30px;
    height: 30px;
    line-height: 30px;
    color: $textColorWhite;
}

.icon-menu:before {
    content: "\e904";
    display: block;
    width: 50px;
    height: 50px;
    line-height: $mainHeaderSizeMobile;
    color: $textColorWhite;
    text-align: center;
    font-size: 18px;
}

.icon-search:before {
    content: "\e907";
    color: $textColorShaded;
}

.icon-filter:before {
    content: "\e915";
    display: block;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    font-size: 10px;
}

.icon-minus:before {
    content: "\e916";
    width: 13px;
    height: 13px;
    line-height: 13px;
    text-align: center;
    font-size: 13px;
    color: $highlightColor;
}

.icon-plus:before {
    content: "\e917";
    width: 13px;
    height: 13px;
    line-height: 13px;
    text-align: center;
    font-size: 13px;
    color: $highlightColor;
}

.icon-arrow-up:before {
    content: "\e901";
    display: block;
    -webkit-transform: rotate(-90deg);
    /* Chrome and other webkit browsers */
    -moz-transform: rotate(-90deg);
    /* FF */
    -o-transform: rotate(-90deg);
    /* Opera */
    -ms-transform: rotate(-90deg);
    /* IE9 */
    transform: rotate(-90deg);
    /* W3C compliant browsers */
}

.icon-arrow-down:before {
    content: "\e901" !important;
    display: block;
    -webkit-transform: rotate(90deg);
    /* Chrome and other webkit browsers */
    -moz-transform: rotate(90deg);
    /* FF */
    -o-transform: rotate(90deg);
    /* Opera */
    -ms-transform: rotate(90deg);
    /* IE9 */
    transform: rotate(90deg);
    /* W3C compliant browsers */
}

.icon-arrow-right:before {
    content: "\e901" !important;
    display: block;
}

.icon-close:before {
    content: "\e906";
    width: 10px;
    height: 10px;
    line-height: 10px;
    text-align: center;
    font-size: 10px;
    color: $textColorDark;
}

.icon-enlarge2:before {
    content: "\e98b";
    width: 10px;
    height: 10px;
    line-height: 10px;
    text-align: center;
    font-size: 10px;
    color: $textColorDark;
}

.icon-error:before {
    content: "\e91e";
    display: block;
    width: 16px;
    height: 14px;
    line-height: 12px;
    font-size: 16px;
    text-align: center;
}

/*------------------------------------*\
    #ICONS-TABLES
\*------------------------------------*/

.icon-sort:after {
    content: "\e912";
    font-family: $iconFont;
    color: $highlightColor;
    font-size: 6px;
    transition: transform 300ms ease;
    position: absolute;
    right: 0;
    top: 7px;
}

.icon-sort-desc {
    -webkit-transform: rotate(180deg);
    /* Chrome and other webkit browsers */
    -moz-transform: rotate(180deg);
    /* FF */
    -o-transform: rotate(180deg);
    /* Opera */
    -ms-transform: rotate(180deg);
    /* IE9 */
    transform: rotate(180deg);
    /* W3C compliant browsers */
}

.icon-sort-desc:after {
    -webkit-transform: rotate(180deg);
    /* Chrome and other webkit browsers */
    -moz-transform: rotate(180deg);
    /* FF */
    -o-transform: rotate(180deg);
    /* Opera */
    -ms-transform: rotate(180deg);
    /* IE9 */
    transform: rotate(180deg);
    /* W3C compliant browsers */
}

.icon-burger {
    font-size: 13px;
    cursor: pointer;
}

.icon-burger {
    &:before {
        content: "\e913";
        color: $textColorBlue;
    }
    &.loading {
        &:before {
            animation: fadingLoader 1s linear infinite;
        }
    }
}

/*------------------------------------*\
    #ICONS-PRODUCT
\*------------------------------------*/

.icon-product {
    display: inline-block;
    font-size: 14px;
    height: 14px;
    width: 14px;
    line-height: 14px;
    cursor: pointer;
    position: relative;
}

.icon-product .path1:before {
    display: inline-block;
    content: '\e908';
    height: 14px;
    width: 14px;
}

.icon-product .path2:before {
    color: $textColorWhite;
    font-size: 14px;
    position: absolute;
    left: 0;
    top: 0;
    height: 14px;
    width: 14px;
}

.icon-right:before {
    content: "\e901";
    display: block;
    font-family: $iconFont;
    width: 30px;
    height: 30px;
    line-height: 30px;
}

.icon-left:before {
    content: "\e901";
    display: block;
    font-family: $iconFont;
    width: 30px;
    height: 30px;
    line-height: 30px;
    -ms-transform: rotate(180deg);
    /* IE 9 */
    -webkit-transform: rotate(180deg);
    /* Safari 3-8 */
    transform: rotate(180deg);
}

.icon-product.icon-q .path1:before {
    color: #424242;
}

.icon-product.icon-q .path2:before {
    content: "\e911";
}

.icon-product.icon-e .path1:before {
    color: #4FA645;
}

.icon-product.icon-e .path2:before {
    content: "\e90d";
}

.icon-product.icon-p .path1:before {
    color: #25529C;
}

.icon-product.icon-p .path2:before {
    content: "\e90f";
}

.icon-product.icon-c .path1:before {
    color: #941E5B;
}

.icon-product.icon-c .path2:before {
    content: "\e909";
}

.icon-product.icon-d .path1:before {
    color: #007DB2;
}

.icon-product.icon-d .path2:before {
    content: "\e90b";
}

/**
 * Logos.
 */

.logo {
    line-height: 53px;
    height: $mainHeaderSizeMobile;
    img {
        height: 16px;
        width: auto;
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    .logo {
        line-height: 50px;
        height: $mainHeaderSizeRegular;
        img {
            height: 21px;
            width: auto;
        }
    }
    .icon-menu {
        display: none;
    }
    .iconfont {
        &.download-pdf {
            font-size: $fontSizeBig;
            line-height: 28px;
            width:600px;
            display: block;
            margin: 0 auto;
        }
    }
}