/*------------------------------------*\
    #PRODUCT-FILTER
\*------------------------------------*/

$filterPadding: 16px;
$filterMarginTop: 94px;
products-filter-mobile {
    background: $backgroundColorWhite;
    display: block;
    padding: 30px 0;
    left: 0;
    &.icon-loading {
        &:before {
            background: $backgroundColorWhite;
            height: 100%;
            width: 100%;
            top: 45px;
            left: 0;
            border-radius: 0;
            margin: 0;
            position: fixed;
        }
        &:after {
            top: 350px;
            position: fixed;
        }
    }
    &.active {
        position: fixed;
        top: $filterMarginTop;
        z-index: 20;
        width: 100%;
        height:calc(100vh - #{$filterMarginTop});
        left: 0;
        button.show-filter {
            display: none;
        }
        h2.filter-title {
            display: block;
            margin: 0;
            padding: 0;
        }
        form {
            display: block;
            background: $backgroundColorWhite;
        }
    }
    h2.filter-title {
        display: none;
    }
    .show-filter {
        background: $backgroundColorMoreBright;
        font-family: $mainFont;
        color: $textColorShaded;
        font-size: $fontSizeRegular;
        line-height: 24px;
        height: 50px;
        text-align: left;
        margin: 0 $borderDimMobile;
        width: calc(100% - (#{$borderDimMobile} * 2));
        position: relative;
        padding: 0 10px;
        &:before {
            content: '\e915';
            font-family: $iconFont;
            font-size: 14px;
            margin: 0 12px 0 6px;
        }
        &:after {
            content: '\e901';
            font-family: $iconFont;
            font-size: 14px;
            margin: 0;
            right: 14px;
            position: absolute;
        }
    }
    form {
        background: $backgroundColorMoreBright;
        box-sizing: border-box;
        display: none;
        padding: 30px $borderDimMobile 120px $borderDimMobile;
        width: 100%;
        height: calc(100vh - 174px);
        overflow-y: auto;
        .second-level-filter {
            li.filter-section {
                margin: 20px 0 0 0;
                width: 100%;
            }
        }
        .third-level-filter {
            margin: 20px 0 0 0;
            width: 100%;
            height: 120px;
            button[disabled="disabled"] {
                background: $backgroundColorBright;
                color: $textColorDisabled;
                cursor: auto;
            }
            &-expanded {
                display: none;
                background: $backgroundColorWhite;
                padding: 30px $borderDimMobile;
                position: fixed;
                top: $filterMarginTop;
                left: 0;
                width: calc(100% - (#{$borderDimMobile} * 2));
                height: calc(100vh - 154px);
                z-index: 20;
                overflow: auto;

                & .filter-label {
                    color: $textColorDark;
                    font-family: $mainFont;
                    font-size: $fontSizeMiddle;
                    line-height: 22px;
                    text-align: center;
                }
            }

            &-properties {
                width: 270px;
            }

            .legend {
                margin: 15px 0 100px 0;
                line-height: 20px;
                font-size: $fontSizeNotSoTiny;
                color: $textColorNotSoDark;
            }
        }
        .active-filter {
            padding: 5px 0 0 0;
            .tags {
                .tag {
                    display: inline-block;
                    margin: 5px 10px 5px 0;
                    padding: 7px 10px;
                    background: $backgroundColorMoreBright;
                    border-radius: 4px;
                    line-height: 18px;
                    font-size: $fontSizeNotSoTiny;
                    white-space: nowrap;
                    &:after {
                        content: '\e906';
                        font-family: $iconFont;
                        font-size: 10px;
                        margin-left: 10px;
                    }
                }
            }
        }
        .button-transparent {
            width: auto;
            height: 18px;
            line-height: 18px;
            font-size: $fontSizeRegular;
            color: $highlightColor;
            margin: 30px 0 20px 0;
        }
        button.label {
            display: flex;
            justify-content: space-between;
            padding: 0 10px 0 15px;
            height: 50px;
            line-height: 50px;
            font-family: $mainFont;
            font-size: $fontSizeMiddle;
            color: $textColorNotSoDark;
            background: $backgroundColorWhite;
            border: 1px solid $textColorBright;
            border-bottom: 1px solid $highlightColorDark;
            border-radius: 4px;
            &:hover {
                background: $backgroundColorWhite;
            }
            &.has-selected-values {
                color: $textColorDark;
                border-bottom: 2px solid $highlightColor;
                font-family: $mainFontMedium;
            }
            i {
                font-size: 12px;
            }
            span {
                white-space: nowrap;
                text-overflow: ellipsis;
                width: calc(100% - 30px);
                overflow: hidden;
                text-align: left;
            }
        }
        .result {
            background: $backgroundColorWhite;
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 15;
        }
    }
    .list {
        background: $backgroundColorWhite;
        display: none;
        position: fixed;
        top: $filterMarginTop;
        left: 0;
        width: 100%;
        height: calc(100vh - #{$filterMarginTop});
        z-index: 20;
        .filter-label {
            color: $textColorDark;
            font-family: $mainFont;
            font-size: $fontSizeMiddle;
            line-height: 22px;
            text-align: center;
            margin: 30px 0 15px 0;
        }
        .search-filter {
            margin: 15px $borderDimMobile 5px $borderDimMobile;
            input {
                padding: 0 10px;
                background: $backgroundColorWhite;
                border: 1px solid #E3E3E3;
                border-left: none;
                border-radius: 2px;
                height: 38px;
                float: left;
                color: $textColorNotSoDark;
                font-family: $mainFont;
                font-size: $fontSizeRegular;
                line-height: 20px;
                width: calc(100% - 30px - (#{$borderDimMobile} * 2));
                -webkit-appearance: none;
                -moz-appearance: none;
            }
            .icon-search {
                background: $backgroundColorWhite;
                border: 1px solid #E3E3E3;
                border-right: none;
                float: left;
                color: #666666;
                &:before {
                    display: block;
                    width: 30px;
                    height: 36px;
                    line-height: 37px;
                    color: #9B9B9B;
                    text-align: right;
                    font-size: 18px;
                }
            }
            .close {
                display: none;
                position: absolute;
                right: 2px;
                top: 1px;
                height: 36px;
                width: 38px;
                line-height: 36px;
                text-align: center;
                padding: 0;
                &:before {
                    content: '\e906';
                    font-family: $iconFont;
                    font-size: 12px;
                    color: $textColorBlack;
                }
            }
        }
        .list-wrapper {
            ul {
                margin: 5px 0 0 0;
                li {
                    padding: 10px $borderDimMobile;
                    &:hover {
                        background-color: $buttonMouserColor;
                        color: $highlightColor;
                        text-decoration: none;
                    }
                    &.label {
                        color: $textColorDark;
                        font-family: $mainFontBold;
                        font-size: $fontSizeMiddle;
                        line-height: 21px;
                    }
                }
            }
        }
    }
    button.apply,
    button.cancel {
        height: 50px;
        width: 50%;
        background-color: $backgroundColorWhite;
        box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.2);
        color: $highlightColor;
        font-family: $mainFontBold;
        font-size: $fontSizeRegular;
        line-height: 21px;
        padding: 0;
        position: absolute;
        bottom: 0;
        z-index: 15;
        left: 0;
        text-align: center;
    }
    button.cancel {
        color: inherit;
        right: 0;
        left: auto;
        &:before {
            content: '';
            display: block;
            position: absolute;
            left: -5px;
            background: $backgroundColorWhite;
            height: 50px;
            width: 10px;
            top: 0;
        }
    }
    .search-alphabet {
        display: flex;
        flex-direction: row;
        position: relative;
        top: 0;
        margin: 20px $borderDimMobile;
        padding: 0;
        background: none;
        li {
            width: 41px;
        }
        li:first-child {
            width: 88px;
        }
        li+li {
            margin-left: 1px;
        }
        button {
            height: 41px;
            line-height: 38px;
            color: $textColorDark;
            background: $backgroundColorBright;
            border-top: 3px solid transparent;
            font-family: $mainFont;
            &.active {
                color: $textColorDark;
                background: $backgroundColorWhite;
                border-color: $highlightColor;
            }
            &[disabled] {
                color: $textColorDisabled;
                cursor: auto;
            }
            &.active {
                color: $textColorBlue;
            }
        }
    }
    .open {
        button.label {
            border-bottom: none;
            border-radius: 4px 4px 0 0;
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
            color: $textColorDark;
            i {
                transform: rotate(-180deg);
            }
        }
        .list {
            display: block;
        }
    }
}

/* iOS special spacing */
.ios.iphone_x products-filter-mobile form .third-level-filter .legend {
    margin: 15px 0 150px 0;
}

/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    products-filter-mobile {
        .show-filter {
            margin: 0 $borderDimTablet;
            width: calc(100% - (#{$borderDimTablet} * 2));
        }
        form {
            padding: 30px $borderDimTablet 70px $borderDimTablet;
            .third-level-filter {
                &-expanded {
                    padding: 30px $borderDimTablet;
                    width: calc(100% - (#{$borderDimTablet} * 2));
                }
            }
        }
        .list {
            .search-filter {
                margin: 15px $borderDimTablet 5px $borderDimTablet;
                input {
                    width: calc(100% - 30px - (#{$borderDimTablet} * 2));
                }
            }
            .list-wrapper {
                ul {
                    li {
                        padding: 10px $borderDimTablet;
                    }
                }
            }
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    products-filter {
        background: $backgroundColorMoreBright;
        display: block;
        position: relative;
        min-height: 138px;
        margin: 40px 0 0 0;
        form {
            .first-level-filter {
                padding: 30px 20px 20px 20px;
                width: 100%;
                background: $backgroundColorMoreBright;
                li {
                    height: 18px;
                }
            }
            .second-level-filter {
                display: flex;
                padding: 0 20px 20px 20px;
                width: 100%;
                background: $backgroundColorMoreBright;
                li.filter-section {
                    width: calc(100% / 4);
                    margin-left: 20px;
                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
            .third-level-filter {
                border-top: 2px solid $backgroundColorWhite;
                box-sizing: border-box;
                margin-top: 2px;
                padding: 0 20px;
                width: 100%;
                button {
                    font-size: 18px;

                    &[disabled="disabled"] {
                        color: $textColorDisabled;
                        cursor: auto;
                        i:before {
                            color: $textColorDisabled;
                        }
                    }
                    i.icon-plus {
                        line-height: 0;
                    }
                    i.active:before {
                        content: '\e916';
                    }
                }
                &-expanded {
                    display: none;
                    margin-bottom: 20px;
                }
                &-properties {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: -15px;
                    width: 100%;
                    li {
                        margin: 0 0 15px 20px;
                        width: calc(25% - 15px);
                        &:nth-child(4n - 3) {
                            margin-left: 0;
                        }
                    }
                }
                legend {
                    display: block;
                    margin-bottom: 5px;
                    line-height: 20px;
                    font-size: $fontSizeNotSoTiny;
                    color: $textColorNotSoDark;
                }
                .legend {
                    margin: 15px 0 0 0;
                    line-height: 20px;
                    font-size: $fontSizeNotSoTiny;
                    color: $textColorNotSoDark;
                }
            }
            .fourth-level-filter {
                background: $backgroundColorWhite;
                display: flex;
                padding-top: 35px;
                width: 100%;
                .label {
                    margin-right: 10px;
                    padding-top: 10px;
                    font-family: $mainFontBold;
                    font-size: $fontSizeMiddle;
                    white-space: nowrap;
                    &:after {
                        content: ':';
                    }
                }
                .active-filter {
                    .tags {
                        .tag {
                            display: inline-block;
                            margin: 5px 10px 5px 0;
                            padding: 7px 10px;
                            background: $backgroundColorMoreBright;
                            border-radius: 4px;
                            cursor: pointer;
                            line-height: 18px;
                            font-size: $fontSizeNotSoTiny;
                            white-space: nowrap;
                            &:after {
                                content: '\e906';
                                font-family: $iconFont;
                                font-size: 10px;
                                margin-left: 10px;
                            }
                        }
                    }
                }
                .button-transparent {
                    margin: 5px 0 0 60px;
                    width: auto;
                    min-width: 173px;
                    height: 30px;
                    line-height: 30px;
                    font-size: $fontSizeMiddle;
                    color: $highlightColor;
                }
            }
            button.label {
                display: flex;
                justify-content: space-between;
                padding: 0 10px 0 15px;
                height: 50px;
                line-height: 50px;
                font-family: $mainFont;
                font-size: $fontSizeMiddle;
                color: $textColorNotSoDark;
                background: $backgroundColorWhite;
                border-bottom: 1px solid $highlightColorDark;
                border-radius: 4px;
                &:hover {
                    background: $backgroundColorWhite;
                }
                &.has-selected-values {
                    color: $textColorDark;
                    border-bottom: 2px solid $highlightColor;
                    font-family: $mainFontMedium;
                }
                i {
                    font-size: 12px;
                }
                span {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: calc(100% - 30px);
                    overflow: hidden;
                    text-align: left;
                }
            }
        }
        .list {
            background: $backgroundColorWhite;
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
            border-radius: 0 4px 4px 4px;
            display: none;
            position: absolute;
            top: 118px;
            left: 0;
            margin: 0 20px;
            width: calc(100% - 40px);
            z-index: 2;
            &:before {
                content: '';
                display: block;
                width: calc(100% / 4 - 15px);
                height: 4px;
                background: $backgroundColorWhite;
                position: absolute;
                top: -4px
            }
            .search-filter {
                position: relative;
                margin: 15px $borderDimMobile 5px $borderDimMobile;
                input {
                    padding: 0 10px;
                    background: $backgroundColorWhite;
                    border: 1px solid #E3E3E3;
                    border-left: none;
                    border-radius: 2px;
                    height: 38px;
                    float: left;
                    width: 530px;
                    color: $textColorNotSoDark;
                    font-family: $mainFont;
                    font-size: $fontSizeRegular;
                    line-height: 20px;
                }
                .icon-search {
                    background: $backgroundColorWhite;
                    border: 1px solid #E3E3E3;
                    border-right: none;
                    float: left;
                    color: #666666;
                    &:before {
                        display: block;
                        width: 30px;
                        height: 36px;
                        line-height: 37px;
                        color: #9B9B9B;
                        text-align: right;
                        font-size: 18px;
                    }
                }
                .close {
                    display: none;
                    position: absolute;
                    right: 2px;
                    top: 1px;
                    height: 36px;
                    width: 38px;
                    line-height: 36px;
                    text-align: center;
                    padding: 0;
                    &:before {
                        content: '\e906';
                        font-family: $iconFont;
                        font-size: 12px;
                        color: $textColorBlack;
                    }
                }
            }
            .list-wrapper {
                min-height: 150px;
                max-height: 390px;
                overflow-x: auto;
                margin: 0 0 55px 0;
                position: relative;
                &.icon-loading {
                    &:before {
                        background: $backgroundColorWhite;
                        height: 100%;
                        width: 100%;
                        top: 0;
                        left: 0;
                        border-radius: 0;
                        margin: 0;
                    }
                }
                ul {
                    margin: 5px 0 0 0;
                    width: 50%;
                    float: left;
                    li {
                        padding: 10px $borderDimMobile;
                        &:hover {
                            background-color: $buttonMouserColor;
                            color: $highlightColor;
                            text-decoration: none;
                        }
                        &.label {
                            background: none;
                            color: $textColorDark;
                            font-family: $mainFontBold;
                            font-size: $fontSizeMiddle;
                            line-height: 21px;
                        }
                    }
                }
            }
            button.apply {
                height: 50px;
                width: 100%;
                border-radius: 0 0 2px 2px;
                background-color: $backgroundColorWhite;
                box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.2);
                text-align: left;
                color: $highlightColor;
                font-family: $mainFontBold;
                font-size: $fontSizeRegular;
                line-height: 21px;
                padding: 0 $borderDimMobile;
                position: absolute;
                bottom: 0px;
                z-index: 15;
            }
        }
        .search-alphabet {
            display: flex;
            flex-direction: row;
            position: relative;
            top: 0;
            margin: 20px $borderDimMobile;
            padding: 0;
            background: none;
            li {
                position: relative;
                width: 41px;
            }
            li:first-child {
                width: 88px;
            }
            li+li {
                margin-left: 5px;
            }
            button {
                height: 40px;
                line-height: 38px;
                color: $textColorDark;
                background: $backgroundColorBright;
                border-top: 3px solid transparent;
                font-family: $mainFont;
                font-size: $fontSizeMiddle;
                text-transform: uppercase;
                padding: 0;
                &[disabled] {
                    color: $textColorDisabled;
                    cursor: auto;
                }
                &.active,
                &:hover:not([disabled]) {
                    font-family: $mainFontBold;
                    color: $textColorDark;
                    background: $backgroundColorWhite;
                    border-color: $highlightColor;
                }
                &:hover:not(.active):not([disabled]) {
                    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
                }
                &.active:after,
                &:hover:not([disabled]):after {
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: -5px;
                    width: 100%;
                    height: 5px;
                    background: $backgroundColorWhite;
                }
            }
        }
        .open {
            button.label {
                border-bottom: none;
                border-radius: 4px 4px 0 0;
                box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
                color: $textColorDark;
                i {
                    transform: rotate(-180deg);
                }
            }
            .list {
                display: block;
                padding-bottom: 40px;
            }
        }
    }
}