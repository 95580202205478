/*------------------------------------*\
    #FLYOUT
\*------------------------------------*/

/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {}

/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    flyout-documents {
        display: block;
        width: 187px;
        border-radius: 4px;
        background: $backgroundColorWhite;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
        z-index: 1000;
        padding: 15px;
        &:before {
            text-shadow: 0px 1px rgba(0, 0, 0, 0.14);
            color: $backgroundColorWhite;
            font-family: $iconFont;
            font-size: 15px;
            position: absolute;
            content: '\e912';
            left: 50px;
            top: -14px;
            display: block;
            transform: rotate(180deg);
        }
        &.align-right {
            &:before {
                left: auto;
                right: 44px;
            }
        }
        &.list-view {
            .content {
                ul {
                    li {
                        border: none;
                        float: left;
                        font-size: $fontSizeRegular;
                        padding: 0 3px 0 0;
                        &:after {
                            content: '|';
                        }
                        &:last-child {
                            &:after {
                                content: '';
                            }
                        }
                        &.label {
                            margin: 5px 0 0 0;
                            width: 100%;
                            &:after {
                                content: '';
                            }
                        }
                    }
                }
            }
        }
        a {
            height: 24px;
            color: $highlightColor;
            line-height: 24px;
        }
        .content {
            .identifier {
                color: $textColorDark;
                font-family: $mainFont;
                font-size: $fontSizeNotSoTiny;
                line-height: 18px;
                display: block;
                margin: 0 0 3px 0;
            }
            .label {
                color: $textColorDark;
                display: block;
                font-family: $mainFont;
                font-size: $fontSizeMiddle;
                line-height: 25px;
                margin: 0 0 2px 0;
            }
            ul {
                li {
                    border-bottom: 1px solid #9B9B9B;
                    font-size: $fontSizeRegular;
                    line-height: 22px;
                    padding: 7px 0;
                    &:last-child {
                        border: none;
                    }
                }
            }
        }
    }
}