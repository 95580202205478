/*------------------------------------*\
    #FIND-PRODUCTS
\*------------------------------------*/

.yield-matrix {
    @extend .find-products;
}

/*------------------------------------*\
    #MEDIA-QUERY-MOBILE
\*------------------------------------*/

@include mediaQueryMobile {
  .heading-max-rendite {
    p {
      margin: 40px 16px 0 16px;
      line-height: 32px;
      font-weight: 400;
      font-size: 28px;
      text-align: center;
    }
  }
  tabs#yield-matrix-tabs{
    tab {
     &:before {
        height: initial;
      }
    }
  }
}

/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
  tabs#yield-matrix-tabs{
    instrument-table-mobile {
      table.desc{
        margin: 20px 0 0 0;
      }
    }
    tab:before{
      height: 0;
    }
  }
}

/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM-REGULAR
\*------------------------------------*/

@include mediaQueryMediumRegular {
  .heading-max-rendite {
    p {
      margin: 40px 16px 0 16px;
      line-height: 32px;
      font-weight: 400;
      font-size: 28px;
      text-align: center;
    }
  }
}

/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    .yield-matrix {
        margin-top: 0;
        products-filter {
            margin-top: 40px;
            min-height: auto;
            form {
                .second-level-filter {
                    padding-top: 20px;
                    li.filter-section {
                        width: calc(100% / 3);
                    }
                }
            }
            product-filter-underlying {
                .list {
                    top: 71px;
                    &:before {
                        left: 0;
                        width: calc(100% / 3 - 13px);
                    }
                }
            }
            product-filter-dropdown {
                &.currency-filter {
                    .list {
                        top: 71px;
                        left: calc(100% / 3 - 6px);
                        width: calc(100% / 3 - 28px);
                    }
                }
                &.distanceRange2BarrierLevel-filter {
                    .list {
                        top: 71px;
                        left: calc((100% / 3)  * 2  - 14px);
                        width: calc(100% / 3 - 26px);
                    }
                }
            }
        }

      .heading-max-rendite{
        display: flex;
        justify-content: flex-end;
        width: 912px;
        margin-top: 92px;
        margin-bottom: -52px;
        span{
            display: inline-block;
            position: relative;
        }
        span:before, span:after{
          content: "";
          position: absolute;
          height: 4px;
          border-top: 3px solid #d8d8d8;
          top: 10px;
          width: 225px;
        }
        span:before{
          right: 100%;
          margin-right: 42px;
        }
        span:after{
          left: 100%;
          margin-left: 42px;
        }
      }

    }
}