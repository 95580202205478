/*------------------------------------*\
    #PRODUCT-LIST
\*------------------------------------*/

#product-list {
    .icon-loading-history {
        opacity: 0.1;
    }
    .icon-loading-history:before {
        content: "X";
        position: fixed;
        width: 100%;
        text-align: center;
        display: block;
        z-index: 9999;
        margin-top: 50px;
    }

    tabs.tabs-product-list {
        >nav {
            display: none;
        }
    }
    header {
        p {
            color: $textColorDark;
            font-family: $mainFont;
            font-size: $fontSizeRegular;
            line-height: 24px;
            margin: 0 $borderDimMobile;
        }
    }
    .product-legend {
        padding: $paddingDimMobile $borderDimMobile 0 $borderDimMobile;
        li {
            color: $textColorMain;
            font-family: $mainFont;
            font-size: 14px;
            line-height: 16px;
            margin: 0 0 0 0;
            padding-right: 5px;
            &.label-prices-indicative {
                margin-top: 20px;
                font-family: $mainFontLight;
                font-size: 12px;
                line-height: 14px;
            }
            .icon-product {
                position: relative;
                top: 2px;
                margin-right: 5px;
                cursor: auto;
            }
        }
    }

    li.label-prices-indicative.footnote-text {
        margin-top: 0;
    }
}

.instrument-table-loader {
    min-height: 100px;
    position: relative;
    &.icon-loading:before,
    &.icon-loading:after {
        background: #FFF;
        top: 70%;
    }
}
/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    #product-list {
        header {
            p {
                font-size: $fontSizeBig;
                line-height: 35px;
                margin: 0 $borderDimTablet;
            }
        }
        .product-legend {
            padding: $paddingDimTablet $borderDimTablet 0 $borderDimTablet;
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    #product-list {
        section {
            padding: 0;
        }
        header {
            p {
                margin: $borderDimDesktop $borderDimDesktop 0 $borderDimDesktop;
            }
        }
        tabs {
            &.tabs-product-list {
                >nav {
                    display: block;
                    ul {
                        padding-top: 45px;
                        li {
                            a {
                                padding: 12px 40px;
                                line-height: 26px;
                                font-size: $fontSizeBig;
                                border-top-width: 6px;
                            }
                            &.active a {
                                padding: 12px 39px;
                            }
                        }
                    }
                }
                >tab:before {
                    height: 0px;
                }
            }
        }
        .products-in-subscription flyout-documents a,
        .find-products flyout-documents a,
        .recently-issued-products flyout-documents a {
            font-size: 16px;
            line-height: 22px;
        }
        .product-legend {
            padding: $paddingDimDesktop $borderDimDesktop 0 $borderDimDesktop;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            li {
                &.label-prices-indicative {
                    width: 100%;
                    font-family: $mainFont;
                    font-size: 14px;
                    line-height: 22px;
                }
            }
        }
    }
}