/*------------------------------------*\
  #ERROR-MODULE
\*------------------------------------*/

error {
    overflow-x: hidden;
    overflow-y: auto;

    .request-error {
        display: block;
        color: #707172;
        height: 100%;
        min-height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 4000;
        background: none repeat scroll 0 0 #c5c5c5;
        opacity: 0.7;
    }

    .dialog {
        position: fixed;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 5000;

        &-content {
            position: relative;
            margin: 100px auto;
            width: 75%;
            max-width: 100%;
            box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.4);
            -moz-box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.4);
            -webkit-box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.4);
            background-color: $backgroundColorWhite;
            outline: 0;
            padding: 15px 10px;
        }

        &-header {
            padding: 15px;
            border-bottom: 1px solid #e5e5e5;

            button {
                width: 37px;
                -webkit-appearance: none;
                padding: 0;
                cursor: pointer;
                background: transparent;
                border: 0;
                margin-top: -2px;
                float: right;
                font-size: 21px;
                font-weight: bold;
                line-height: 1;
                color: $textColorDark;
                text-shadow: 0 1px 0 $textColorWhite;
                filter: alpha(opacity=20);
                opacity: .2;
            }
        }

        &-body {
            position: relative;
            padding: 15px;

            .scrollbar {
                height: 380px;
                overflow-x: hidden;
                overflow-y: auto;
            }
        }

        &-footer {
            padding: 15px;
            text-align: right;
            border-top: 1px solid #e5e5e5;
        }

    }
}
