/*------------------------------------*\
    #FONTS
\*------------------------------------*/

$fontSizeVeryTiny: 12px;
$fontSizeTiny: 13px;
$fontSizeNotSoTiny: 14px;
$fontSizeRegular: 16px;
$fontSizeSmall: 17px;
$fontSizeMiddle: 18px;
$fontSizeNotSoBig: 21px;
$fontSizeBig: 24px;
$fontSizeBigger: 28px;
$fontSizeNotSoTall: 34px;
$fontSizeTall: 48px;
$fontSizeVeryTall: 56px;

$iconFont: 'icomoon';
$mainFont: 'Yoga Sans for LUKB W01 Regular';
$mainFontBold: 'Yoga Sans for LUKB W01 Bold';
$mainFontLight: "Yoga Sans for LUKB W01 Light";
$mainFontLightSerif: "Yoga for LUKB W01 Light";
$mainFontMedium: 'Yoga Sans for LUKB W01 Medium';
$mainFontThin: 'Yoga Sans for LUKB W01 Thin';

@font-face {
    font-family: $mainFont;
    src: url("../fonts/53a0777f-e3bf-4ef2-bf6e-fbd8646e2c1e.eot?#iefix");
    src: url("../fonts/53a0777f-e3bf-4ef2-bf6e-fbd8646e2c1e.eot?#iefix") format("eot"), url("../fonts/88dd518b-f0d0-4301-9328-7166b48b045f.woff") format("woff"), url("../fonts/247cf302-e3ae-41f8-8864-ab4f1d29ad3c.ttf") format("truetype"), url("../fonts/8f3d8cea-d261-4460-af01-d118ac58a5da.svg#8f3d8cea-d261-4460-af01-d118ac58a5da") format("svg");
}

@font-face {
    font-family: $mainFontBold;
    src: url("../fonts/9cba07ca-47fc-4c73-a7ed-76ba6a132cb1.eot?#iefix");
    src: url("../fonts/9cba07ca-47fc-4c73-a7ed-76ba6a132cb1.eot?#iefix") format("eot"), url("../fonts/56500f0c-6386-4fe2-bb76-2fc7a84b1fce.woff") format("woff"), url("../fonts/55630368-207d-4dd4-b067-6725f0c193fe.ttf") format("truetype"), url("../fonts/5a696ebe-b984-4c51-a314-141708012176.svg#5a696ebe-b984-4c51-a314-141708012176") format("svg");
}

@font-face {
    font-family: $mainFontLight;
    src: url("../fonts/3c1ff562-8341-486c-b7ce-f2e75b692cd2.eot?#iefix");
    src: url("../fonts/3c1ff562-8341-486c-b7ce-f2e75b692cd2.eot?#iefix") format("eot"), url("../fonts/21c5d20e-f022-4113-8665-b9f2f70253de.woff") format("woff"), url("../fonts/96ae7b84-ee82-4b49-93ea-0c5ff2e1e5ab.ttf") format("truetype"), url("../fonts/68cbd961-8e29-4dbf-a543-400af024f66f.svg#68cbd961-8e29-4dbf-a543-400af024f66f") format("svg");
}

@font-face {
    font-family: $mainFontLightSerif;
    src: url("../fonts/bf5f0d4d-278f-4de6-839c-92ec64f78a0b.eot?#iefix");
    src: url("../fonts/bf5f0d4d-278f-4de6-839c-92ec64f78a0b.eot?#iefix") format("eot"), url("../fonts/e6729cc8-44c4-4806-96a4-219d099e4c4a.woff") format("woff"), url("../fonts/392aaae3-4145-4517-a3f9-19a89d593a48.ttf") format("truetype"), url("../fonts/dac7668a-cac3-47c5-8b46-b41c21514956.svg#dac7668a-cac3-47c5-8b46-b41c21514956") format("svg");
}

@font-face {
    font-family: $mainFontMedium;
    src: url("../fonts/affec004-db45-47be-a1a0-c055817c85d2.eot?#iefix");
    src: url("../fonts/affec004-db45-47be-a1a0-c055817c85d2.eot?#iefix") format("eot"), url("../fonts/4497f8c5-276e-44e5-bdac-9c2719886437.woff") format("woff"), url("../fonts/0dfa560c-a588-42ff-822d-5f0ec0b6dd79.ttf") format("truetype"), url("../fonts/97187016-2dd7-4472-b105-6f349d02c0f6.svg#97187016-2dd7-4472-b105-6f349d02c0f6") format("svg");
}

@font-face {
    font-family: $mainFontThin;
    src: url("../fonts/92425944-6fa1-4478-8fa1-4c5151af536c.eot?#iefix");
    src: url("../fonts/92425944-6fa1-4478-8fa1-4c5151af536c.eot?#iefix") format("eot"), url("../fonts/3734db24-70d2-4944-81f8-c75a10723339.woff") format("woff"), url("../fonts/10c0e28e-319c-48da-ad6a-8073ff5fc409.ttf") format("truetype"), url("../fonts/341ade89-fcd1-4fe8-b90d-779e1c5d95cb.svg#341ade89-fcd1-4fe8-b90d-779e1c5d95cb") format("svg");
}

@font-face { 
    font-family: $iconFont;
    src:  url('../fonts/icomoon.eot?1l67qa');
    src:  url('../fonts/icomoon.eot?1l67qa#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.ttf?1l67qa') format('truetype'),
        url('../fonts/icomoon.woff?1l67qa') format('woff'),
        url('../fonts/icomoon.svg?1l67qa#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}