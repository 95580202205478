/*------------------------------------*\
    #CMS
\*------------------------------------*/

.page-container{
    overflow: hidden;

    
    &-row {
        display: block;
        width: 100%;
        min-height: 30px;
        overflow: hidden;
        margin: auto;

        
        &-cell{
            display: block;
            min-height: 20px;
            float: left;
            width: 100%;
        }
    }
    
    &-row + &-row {
        margin-top: 10px;
    }
}
/* For video responsiveness */
.video-full-width {
  width: 100%;
}