/*------------------------------------*\
    #HEADLINE
\*------------------------------------*/

headline, .headline {
    h1 {
        margin: 40px 0 30px 0;
    }
    h2 {
        margin: 0 0 40px 0;
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    headline, .headline {
        h1 {
            margin: 40px 0 30px 0;
        }
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    headline, .headline {
        h1 {
            margin: 80px 0 40px 0;
        }
    }
}