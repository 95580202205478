/*------------------------------------*\
    #PRODUCT-FILTER-SLIDER
\*------------------------------------*/

product-filter-slider {
    .slider-field {
        margin: 30px 0 0 0;
        width: 100%;
        fieldset {
            display: block;
            legend {
                display: block;
                margin-bottom: 9px;
                line-height: 22px;
                font-family: $mainFontBold;
                font-size: $fontSizeMiddle;
                color: $textColorDark;
            }
            /**
             * Set default input text element styles to a wrapper container.
             */
            div {
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                float: left;
                padding: 0 5px;
                width: calc(50% - 5px);
                height: 40px;
                background: $backgroundColorWhite;
                border-top: 1px solid $backgroundColorBright;
                border-right: 1px solid $backgroundColorBright;
                border-bottom: 1px solid $highlightColorDark;
                border-left: 1px solid $backgroundColorBright;
                border-radius: 4px;
                label {
                    line-height: 38px;
                    font-size: $fontSizeRegular;
                    color: $textColorNotSoDark;
                }
                /**
                 * Overwrite default input text element styles.
                 */
                input {
                    margin-top: 7px;
                    width: 50%;
                    height: 25px;
                    line-height: 25px;
                    font-size: $fontSizeRegular;
                    text-align: right;
                    border: none;
                    &::placeholder {
                        opacity: 1;
                        color: $textColorNotSoDark;
                    }
                    &:focus {
                        color: $textColorNotSoDark;
                        border-bottom: none;
                        &::placeholder {
                            color: $textColorMain;
                        }
                    }
                }
                .icon-close {
                    visibility: hidden;
                    cursor: pointer;
                    &:before {
                        display: block;
                        margin-top: 15px;
                    }
                    +input:focus {
                        visibility: visible;
                    }
                }
                input:focus+.icon-close {
                    visibility: visible;
                }
                /**
                 * Set active style for wrapper container.
                 */
                &.has-selected-values {
                    border-bottom: 2px solid $highlightColor;
                    label,
                    input {
                        color: $textColorDark;
                        font-weight: bold;
                    }
                    .icon-close {
                        visibility: visible;
                    }
                }
            }
            div+div {
                margin-left: 10px;
            }
        }
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    product-filter-slider {}
}


/*------------------------------------*\
    #MEDIA-QUERY-SLIDER
\*------------------------------------*/

@include mediaQueryRegular {
    product-filter-slider {
        .slider-field {
            margin: 20px 0 0 0;
            
            fieldset {
                legend {
                    margin-bottom: 5px;
                    line-height: 20px;
                    font-family: $mainFont;
                    font-size: $fontSizeNotSoTiny;
                    color: $textColorNotSoDark;
                }
                
                /**
                 * Set default input text element styles to a wrapper container.
                 */
                div {
                    border-top-color: transparent;
                    border-right-color: transparent;
                    border-left-color: transparent;
                    
                    /**
                     * Overwrite default input text element styles.
                     */
                    input {
                        text-align: center;
                    }
                }
            }
        }
    }
}