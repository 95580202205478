/*------------------------------------*\
    #DisclaimerPopup
\*------------------------------------*/

.show-disclaimer {
    menu,
    search,
    main-footer {
        display: none;
    }
}

disclaimer-popup {
    #background-overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        z-index: 1000;
    }

    .disclaimer-popup-wrap {
        max-width: 980px;
        margin: 120px auto 0;
        overflow: hidden;
    }

    .disclaimer-container {
        background-color: #FFFFFF;
        height: calc(100vh - 120px);
        overflow: hidden;

        &-row {
            display: block;
            min-height: 30px;
            overflow: hidden;

            &-cell {
                display: block;
                padding: $paddingDimMobile 0;
                min-height: 20px;
                float: left;
            }
        }

        &-row + &-row {
            margin-top: 10px;
        }

        .disclaimer-container-row-cell html-text h1 {
            margin: 20px 0 30px 0;
        }
    }

    .disclaimer-dialog {
        position: relative;
        display: block;
        overflow: auto;
        background: $backgroundColorMoreBright;
        color: $textColorDark;
        padding: 20px;
        height: 500px;

        fieldset.checkbox-group {
            label {
                color: $textColorDark;
            }
        }

        &-header {
            h3 {
                text-align: left;
                margin: 20px 0 0 0;
                padding-bottom: 10px;
            }
        }

        &-body {
            margin: auto;

            p {
                margin: 20px 0;
            }

            form {
                margin: 35px 0;
            }
        }


        &-footer {
            margin: auto;
            padding: 20px 0;

            button, .button {
                width: 48.5%;
                float: left;
                margin-right: 2.5%;
            }

            button:last-child , .button:last-child {
                margin-right: 0;
            }

            small {
                display: block;
                bottom: 28px;
                width: 100%;
                margin-top: 30px;
            }
        }

    }
}


/*------------------------------------*\
    #MEDIA-QUERY-MOBILE
\*------------------------------------*/

@include mediaQueryMobile {

    body {
        &.displaying-disclaimer {
            #ui-view, main-footer {
                display: none;
            }
        }
    }

    disclaimer-popup {

        #background-overlay {
            z-index: 999;
        }

        .disclaimer-popup-wrap {
            margin: 0;
            max-height: 100vh;
            overflow: auto;
        }

        .disclaimer-container {
            padding-top: 92px;
            height: auto;
        }

       .disclaimer-container-row, .disclaimer-dialog   {
            height: auto;
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    disclaimer-popup {

        #background-overlay {
            z-index: 999;
        }

        .disclaimer-container {
            width: 55%;
            float: left;
            overflow: auto;
            height: calc(100vh - 100px);

            html-text {
                section {
                    h2 {
                        padding-top: 0;
                    }
                }
            }
            &-row {
                &-cell {
                    padding: $paddingDimTablet 0;

                    html-text h1 {
                        margin: 10px 0 30px 0;
                    }
                }
            }
        }

        .disclaimer-dialog {
            padding: 30px 20px;
            height: calc(100vh - 155px);

            &-header {
                h3 {
                    margin: 20px 0 0 0;
                }
            }
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM-REGULAR
\*------------------------------------*/

@include mediaQueryMediumRegular {

    body {
        &.displaying-disclaimer {
            #ui-view, main-footer {
                display: none;
            }
        }
    }

    disclaimer-popup {
        #background-overlay {
            background-color: #fff;
        }

        .disclaimer-popup-wrap {
            max-height: none;
            overflow: visible;
            padding: 0;
            margin: 0;
        }

        .disclaimer-container {
            height: 100vh;
        }

        .disclaimer-container-row {
            padding: 0;
            overflow: auto;
            height: auto;
        }

        .disclaimer-container-row-cell, .disclaimer-dialog {
            padding-top: 125px;
        }
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    .show-disclaimer {
        menu,
        search,
        main-footer,
        .icon-menu {
            display: none;
        }
    }

  disclaimer-popup {

      /* #background-overlay {
          z-index: 1000;
      } */

      .disclaimer-container {
          width: 60%;
          height: calc(100vh - 130px);

          &-row {
              &-cell {
                  padding: $paddingDimDesktop 0;
              }
          }

          html-text {
              section {
                  padding: 0;

                  h2 {
                      padding-top: 0;
                  }
                  h4 {
                      margin: 20px 0 10px 0;
                  }
              }
          }

          .disclaimer-container-row-cell html-text h1 {
              margin: 30px 0 40px 0;
          }

          .disclaimer-container-row-cell html-text h4 {
              margin: 40px 0 10px 0;
          }
      }

      .disclaimer-dialog {
          height: calc(100vh - 210px);
          padding: 40px;

          fieldset {
              &.checkbox-group {
                  label {
                      font-size: $fontSizeMiddle;
                      line-height: 24px;
                  }

                  input,
                  input + .checkbox-label:before {
                      top: 3px;
                  }
              }
          }

          &-header {
              h3 {
                  font-size: $fontSizeBigger;
                  line-height: 32px;
                  margin: 20px 0 0 0;
              }
          }

          &-body {
              form {
                  margin: 35px 0;
              }
          }

          &-footer {
              margin: auto;
              padding: 20px 0;

              button, .button {
                  width: 148px;
              }

              small {
                  width: 100%;
              }
          }
      }
  }
}
