/*------------------------------------*\
    #NOTIFICATION-BOX
\*------------------------------------*/

notification-box {
    background: $backgroundColorWhite;
    display: block;
    overflow: hidden;
    padding: 10px $borderDimMobile;
    p {
        color: $textColorDark;
        font-size: $fontSizeRegular;
        font-family: $mainFont;
        line-height: 24px;
        margin: 0;
        span {
            margin: 0 5px 0 0;
        }
        a {
            color: $highlightColor;
            font-size: $fontSizeRegular;
            font-family: $mainFontMedium;
            display: inline-block;
            margin: 4px 0 0 0;
            padding: 0 0 0 18px;
            line-height: 24px;
            position: relative;
            &:before {
                content: '\e902';
                font-family: $iconFont;
                font-size: 13px;
                margin: 0 5px 0 0;
                position: absolute;
                left: 0;
            }
        }
    }
    p+p {
        margin-top: 10px;
    }
}

/*------------------------------------*\
  #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {}

/*------------------------------------*\
  #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    notification-box {
        p {
            font-size: $fontSizeMiddle;
            a {
                font-size: $fontSizeMiddle;
            }
        }
    }
}