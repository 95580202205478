/*------------------------------------*\
    #GROUP-NAVIGATION
\*------------------------------------*/

group-navigation {
    position: fixed;
    right: 20px;
    top: 215px;
    z-index: 10;
    ul {
        li {
            cursor: pointer;
            color: $textColorDark;
            height: 25px;
            line-height: 25px;
            text-align: right;
            position: relative;
            margin: 0 0 4px 0;
            &.active {
                &:after {
                    border-color: $highlightColor;
                    background: $highlightColor;
                    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.25);
                }
            }
            &:hover {
                em {
                    display: block;
                }
            }
            &:after {
                background: $backgroundColorWhite;
                border: 1px solid $backgroundColorNotSoDark;
                border-radius: 50%;
                content: '';
                display: inline-block;
                height: 12px;
                width: 12px;
                position: absolute;
                top: 5px;
                right: 0;
                box-shadow: 0 1px 3px 0 rgba(0,0,0,0.25);
            }
            em {
                background-color: $backgroundColorWhite;
                border-radius: 4px;
                box-shadow: 0 2px 6px 0 rgba(0,0,0,0.25);
                font-style: normal;
                display: none;
                margin: 0 24px 0 0;
                padding: 0 10px;
                user-select: none;
                font-size: $fontSizeRegular;
                font-family: $mainFont;
            }
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {}

/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {}