/*------------------------------------*\
    #MAIN
\*------------------------------------*/

body {
    margin: 0;
    font-family: $mainFont;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $textColorDark;
    font-size: $fontSizeMiddle;
    background: $backgroundColorWhite;
    overflow-x: hidden;
}

article,
i,
img,
ul,
li,
chart,
h1,
h2,
small,
header {
    box-sizing: border-box;
}

a {
    color: $linkColor;
    text-decoration: none;
    &.external-link {
        &:before {
            font-family: $iconFont!important;
            content: '\e925';
            font-size: 13px;
            padding-right: 8px;
        }
        &:hover {
            text-decoration: none;
        }
    }
}

a:hover {
    text-decoration: underline;
}

small {
    margin: 0;
    line-height: 18px;
    font-weight: normal;
    font-size: $fontSizeNotSoTiny;
    color: $textColorNotSoDark;
}

ul,
ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

p {
    margin: 0;
    line-height: 24px;
    font-family: $mainFontLight;
}

p + p {
    margin: 18px 0 0 0;
}

strong {
    font-family: $mainFontBold;
    font-weight: normal;
}


/**
 * Text highlight.
 */
.text-highlight {
    font-family: $mainFontBold;
}


/**
 * Horizontal line.
 */
horizontal-line {
    display: block;
    position: relative;
    width: 100%;
    height: 28px;
    
    hr {
        position: relative;
        top: calc(50%);
        width: 90%;
        height: 2px;
        background: $backgroundColorMoreBright;
        border: 0;
    }
    
    span {
        position: absolute;
        top: 0;
        left: calc(50% - 22px);
        line-height: 28px;
        width: 44px;
        text-align: center;
        background: $backgroundColorWhite;
    }
}


/**
 * Tooltip.
 */
.highlight-tooltip {
    color: $highlightColor;
    cursor: pointer;
    background: transparent;
    border-bottom: 2px dashed $highlightColor;
}

.tooltip {
    box-sizing: border-box;
    display: none;
    width: auto;
    min-width: 50px;
    max-width: 350px;
    border-radius: 4px;
    background: $backgroundColorWhite;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.25);
    z-index: 999;
    padding: 13px 15px;
    line-height: 18px;
    position: absolute;
    text-align: left;
    left: -30px;
    top: 40px;
    font-size: $fontSizeRegular;
    
    &.show {
        display: block;
    }
    p {
        font-family: $mainFont;
    }
}


/*
 * Responsive definitions.
 * Used in CMS CKEditor.
 */
.smartphone-only {
    display: none;
}

.tablet-only {
    display: none;
}

.desktop-only {
    display: none;
}


/**
 * UI-View will filled with the content.
 */

#ui-view {
    box-sizing: border-box;
    position: relative;
    padding: 94px 0 0 0;
    width: 100%;
    background: $backgroundColorWhite;
    min-height: 100vh;
}


/**
 * Grid system
 */
/*.custom-col-1 {width: 8.33%;}
.custom-col-2 {width: 16.66%;}
.custom-col-3 {width: 25%;}
.custom-col-4 {width: 33.33%;}
.custom-col-5 {width: 41.66%;}
.custom-col-6 {width: 50%;}
.custom-col-7 {width: 58.33%;}
.custom-col-8 {width: 66.66%;}
.custom-col-9 {width: 75%;}
.custom-col-10 {width: 83.33%;}
.custom-col-11 {width: 91.66%;}
.custom-col-12 {width: 100%;}

[class*="custom-col-"] {
    float: left;
    padding: 0;
}
.custom-row::after {
    content: "";
    clear: both;
    display: table;
}*/


/*------------------------------------*\
    #MEDIA-QUERY-MOBILE
\*------------------------------------*/

@include mediaQueryMobile {
    
    /*
     * Responsive definitions.
     */
    .smartphone-only {
        display: inline-block !important;
    }
    .smartphone-only.video-centered {
        display: block !important;
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {

}


/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM-REGULAR
\*------------------------------------*/

@include mediaQueryMediumRegular {
    
    /*
     * Responsive definitions.
     */
    .tablet-only {
        display: inline-block !important;
    }
    .tablet-only.video-centered {
        display: block !important;
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {

    /**
 * Grid system
 */
    .custom-col-1 {width: 8.33%;}
    .custom-col-2 {width: 16.66%;}
    .custom-col-3 {width: 25%;}
    .custom-col-4 {width: 33.33%;}
    .custom-col-5 {width: 41.66%;}
    .custom-col-6 {width: 50%;}
    .custom-col-7 {width: 58.33%;}
    .custom-col-8 {width: 66.66%;}
    .custom-col-9 {width: 75%;}
    .custom-col-10 {width: 83.33%;}
    .custom-col-11 {width: 91.66%;}
    .custom-col-12 {width: 100%;}

    [class*="custom-col-"] {
        float: left;
        padding: 0;
    }
    .custom-row::after {
        content: "";
        clear: both;
        display: table;
    }

    /**
     * Horizontal line.
     */
    horizontal-line {
        hr {
            width: 100%;
        }
        
        span {
            left: calc(50% - 36px);
            width: 72px;
        }
    }
    
    
    /*
     * Responsive definitions.
     */
    .desktop-only {
        display: inline-block !important;
    }
    .desktop-only.video-centered {
        display: block !important;
    }
    
    
    #wrapper {
        max-width: $maxWidth;
        margin: 0 auto;
        position: relative;
        #ui-view {
            padding: 120px 0 0 0;
        }
    }

    #ui-view {
        padding: 120px 0 0 0;
    }

    .saml-xml-textarea {
        width: 100%;
    }
    .saml-radio-btn {
        margin-left: 5px;
    }
}
