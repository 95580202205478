/*------------------------------------*\
    #PRODUCTS-IN-SUBSCRIPTION
\*------------------------------------*/

.products-in-subscription {
    &.icon-loading {
        &:before {
            background: $backgroundColorWhite;
            height: 100%;
            width: 100%;
            top: 45px;
            left: 0;
            border-radius: 0;
            margin: 0;
            position: fixed;
        }
        &:after {
            top: 350px;
            position: fixed;
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    .products-in-subscription {
        &.icon-loading {
            &:before {
                top: 245px;
                position: absolute;
                height: calc(100% - 245px);
            }
            &:after {
                top: 65vh;
                position: absolute;
            }
        }
        section {
            h2 {
                font-size: $fontSizeTall;
                line-height: 54px;
            }           
            separator {
                hr {
                    margin-bottom: 0;
                }
            }
        }
        flyout-documents {
            position: absolute;
            z-index: 10;
            top: 490px;
            right: 6px;
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    .products-in-subscription {
        header {
            margin-top: 0px;
        }
        section {
            h2 {
                padding: 80px 0 0 0;
            }
        }
    }
}