/*------------------------------------*\
    #UTILITIES
\*------------------------------------*/

utilities {
    .headline {
        color: $textColorWhite;
        font-family: $mainFont;
        font-size: $fontSizeBigger;
        line-height: 32px;
        margin: 0;
    }
    ul {
        margin: 31px 0 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        li {
            cursor: pointer;
            line-height: 22px;
            padding: 0 0 0 65px;
            position: relative;
            text-align: left;
            width: 80%;
            a {
                line-height: 22px;
                color: $textColorWhite;
                font-size: $fontSizeMiddle;
                opacity: 1;
                white-space: nowrap;
                &:hover {
                    text-decoration: none;
                }

                &.icon-call:before,
                &.icon-mail:before {
                    position: absolute;
                    left: 10px;
                }

                &.icon-call:before {
                    top: 6px;
                    font-size: 33px;
                }

                &.icon-mail:before {
                    top: -6px;
                    font-size: 30px;
                }

                em {
                    font-style: normal;
                }
            }
        }

        li + li {
            margin-top: 40px;
        }
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    utilities {
        .headline {
            line-height: 54px;
            font-family: $mainFontLight;
            font-size: $fontSizeTall;
            margin: 0;
        }

        ul {
            display: inline-block;

            li {
                &:first-child {
                    border-bottom: none;
                }

                text-align: center;
                float:left;
                padding: 0;
                width: 40vw;
                max-width: 300px;

                a {
                    font-family: $mainFontLight;
                    font-size: $fontSizeBigger;
                    line-height: 35px;

                    &.icon-call:before,
                    &.icon-mail:before {
                        top: 0;
                        font-size: 60px;
                        position: relative;
                        height: 70px;
                        width: 100%;
                        line-height: 70px;
                        padding: 20px 0;
                        left: 0;
                    }

                    em {
                        display: block;
                    }
                }
            }

            li + li {
                margin: 0;
            }
        }
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    utilities {
        ul {
            li {
                width: 300px;
            }
        }
    }
}