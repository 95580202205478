/*------------------------------------*\
    #HEADLINES
\*------------------------------------*/

h1 {
    margin: 0 $borderDimMobile;
    line-height: 38px;
    font-family: $mainFontLight;
    font-weight: normal;
    font-size: $fontSizeNotSoTall;
    text-align: center;
}

h2 {
    margin: 40px $borderDimMobile 0 $borderDimMobile;
    line-height: 32px;
    font-weight: normal;
    font-size: $fontSizeBigger;
    text-align: center;
}

h3 {
    margin: 50px 0 0 0;
    line-height: 28px;
    font-family: $mainFontMedium;
    font-weight: normal;
    font-size: $fontSizeBig;
}

h4 {
    margin: 0;
    line-height: 25px;
    font-weight: normal;
    font-size: $fontSizeNotSoBig;
}

h5 {
    margin: 0;
    line-height: 22px;
    font-family: $mainFontMedium;
    font-weight: normal;
}


/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    h1 {
        margin: 0 $borderDimTablet;
    }

    h2 {
        margin: 0 $borderDimTablet;
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    h1 {
        margin: 0;
        line-height: 65px;
        font-family: $mainFontLight;
        font-size: $fontSizeVeryTall;
    }

    h2 {
        padding: 80px 0 0 0;
        line-height: 54px;
        font-family: $mainFontLight;
        font-size: $fontSizeTall;
    }

    h3 {
        line-height: 32px;
        font-family: $mainFont;
        font-size: $fontSizeBigger;
    }

    h4 {
        line-height: 28px;
        font-family: $mainFontMedium;
        font-size: $fontSizeBig;
    }

    h5 {
        line-height: 25px;
        font-family: $mainFontLight;
        font-size: $fontSizeNotSoBig;
    }
}