/*------------------------------------*\
    #TABS-DYNAMIC-WRAPPER
\*------------------------------------*/


/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {}


/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    tabs-dynamic-wrapper {
        > section {
            padding: 0 $borderDimDesktop;
        }
    }
}