/*------------------------------------*\
    #INSTRUMENT-TABLE
\*------------------------------------*/

instrument-table-mobile {
    display: block;
    font-size: 16px;
    position: relative;
    &.icon-loading:before {
        background: rgba(255, 255, 255, 1);
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        border-radius: 0;
        margin: 0;
    }
    table-mobile {
        display: flex;
        overflow: hidden;
        position: relative;
        .static {
            width: $firstColumnMobile;
            table {
                td {
                    &.active,
                    &.initial-active {
                        background: $backgroundColorBright;

                        & > .sorting-wrapper:after {
                            content: '\e912';
                            color: $highlightColor;
                            font-family: $iconFont;
                            font-size: 5px;
                            position: absolute;
                            right: 0;
                            top: 0;
                            line-height: 5px;
                        }
                    }

                    & > .sorting-wrapper {
                        display: table;
                        position: relative;
                        width: 100%;
                        height: 100%;
                    }

                    span {
                        display: table-cell;
                        text-align: left;
                        word-break: break-word;
                        width: 100%;
                        vertical-align: middle;
                    }
                }
            }
        }
        .scrollable {
            overflow-x: auto;
            flex-grow: 1;
            position: relative;
            width: calc(100% - #{$firstColumnMobile});
            td {
                &:first-child:not([class*=color-]) {
                    background: none;
                    color: $textColorNotSoDark;
                    font-family: $mainFont;
                }

                &.active:not([class*=color-]),
                &.initial-active {
                    background: $backgroundColorTableShaded;
                }
            }
        }
        table {
            display: table;
        }
        .flyout {
            display: none;
            width: auto;
            min-width: 50px;
            border-radius: 4px;
            background: #FFF;
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
            z-index: 10000;
            padding: 13px 15px;
            line-height: 18px;
            position: absolute;
            text-align: center;
            white-space: nowrap;
            margin: 15px 0 0 -30px;
            &:before {
                text-shadow: 0px 1px rgba(0, 0, 0, 0.14);
                color: $backgroundColorWhite;
                font-family: $iconFont;
                font-size: 15px;
                position: absolute;
                content: '\e912';
                left: 25px;
                top: -15px;
                display: block;
                transform: rotate(180deg);
            }
            &.align-right {
                &:before {
                    left: auto;
                    right: 50px;
                }
            }
        }
        .icon {
            cursor: pointer;
            display: inline-block !important;
        }
    }
    .paging {
        background: $highlightColor;
        display: none;
        position: absolute;
        right: 0;
        bottom: 0;
        height: calc(100% - 20px);
        width: 18px;
        padding: 0;
        &:before {
            content: '\e901';
            color: $textColorWhite;
            font-family: $iconFont;
            font-size: 10px;
        }
    }
    .modal {
        .wrapper {
            padding: 50px 20px;
            width: calc(100vw - 100px);
            .content {
                display: flex;
                align-items: center;
                .icon-product {
                    font-size: 50px;
                    margin-right: 15px;
                    width: 50px;
                    .path1:before {
                        height: 50px;
                        width: 50px;
                    }
                    .path2:before {
                        font-size: 50px;
                    }
                }
            }

            .icon-close:before {
                font-size: 18px;
            }
        }
    }
}


/* Hack for Firefox. */
@-moz-document url-prefix() {
    instrument-table-mobile {
        table-mobile {
            .static {
                table {
                    td {
                        &:first-child {
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    instrument-table-mobile {
        .paging {
            height: calc(100% - 50px);
        }
        table-mobile {
            .static {
                width: $firstColumnTablet;
            }
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    instrument-table-mobile {
        display: none;
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-Tablet
\*------------------------------------*/

@include mediaQueryRegular {
    .dividend-table {
        max-width: 373px;
        margin: 0 auto;
    }
}