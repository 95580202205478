/*------------------------------------*\
    #MODAL
\*------------------------------------*/

modal,
.modal {
    background: rgba(0,0,0, 0.85);
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    &.active {
        display: block;
    }
    .wrapper {
        box-sizing: border-box;
        width: calc(100vw - 65px);
        background: $backgroundColorWhite;
        padding: 50px $borderDimMobile;
        margin: 160px auto 0 auto;
        font-family: $mainFont;
        font-size: $fontSizeRegular;
        position: relative;
        .icon-close {
            cursor: pointer;
            display: block;
            height: 25px;
            width: 25px;
            line-height: 25px;
            position: absolute;
            top: $borderDimMobile;
            right: $borderDimMobile;
            text-align: center;
            &:before {
                content: "\e906";
                color: $highlightColor;
                display: block;
                height: 25px;
                width: 25px;
                font-size: 18px;
            }
        }
        a {
            height: 24px;
            color: $highlightColor;
            line-height: 24px;
        }
        .product-link {
            margin: 0 0 10px 0;
        }
        .content {
            margin: 0 0 20px 0;
            &:last-child {
                margin: 0;
            }
            .label {
                color: $textColorDark;
                display: block;
                font-family: $mainFontMedium;
                line-height: 18px;
                margin: 0 0 2px 0;

                &:after {
                    content: ': ';
                }
            }
            ul {
                display: flex;
                flex-wrap: wrap;
                li {
                    &:after {
                        content: '|';
                        display: inline-block;
                        padding: 0 4px 0 0;
                    }
                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {}

/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {}