/*------------------------------------*\
    #SUBSCRIPTION-PRODUCTS
\*------------------------------------*/

subscription-products {
    section {
        text-align: center;
        .button {
            display: inline-block;
            margin: 30px 0 0 0;
        }
        .product-legend {
            padding: $paddingDimMobile $borderDimMobile 0 $borderDimMobile;
            li {
                text-align: left;
                color: $textColorMain;
                font-family: $mainFont;
                font-size: 14px;
                line-height: 16px;
                margin: 0 0 10px 0;
                padding-right: 5px;
                &.label-prices-indicative {
                    margin-top: 20px;
                    font-family: $mainFontLight;
                    font-size: 12px;
                    line-height: 14px;
                }
                .icon-product {
                    position: relative;
                    top: 2px;
                    margin-right: 5px;
                    cursor: auto;
                }
            }
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {}

/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    subscription-products {
        section {
            padding: 0 $borderDimDesktop;
            h2 {
                font-size: $fontSizeTall;
                line-height: 54px;
                padding-top: 80px;
            }
            instrument-table table {
                margin: 40px 0 0 0;
                thead {
                    tr {
                        th {
                            span {
                                font-size: $fontSizeMiddle;
                            }
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            span {
                                font-size: $fontSizeMiddle;
                                i span {
                                    font-size: $fontSizeRegular;
                                }
                                i span.path1, i span.path2 {
                                    font-size: $fontSizeNotSoTiny;
                                }
                            }
                        }
                    }
                }
            }
            .product-legend {
                padding: 50px 50px 0 50px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }
        flyout-documents {
            position: absolute;
            z-index: 10;
            right: 6px;
            a {
                font-size: 16px;
                line-height: 22px;
            }
        }
    }
}