/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/

button,
.button {
    box-sizing: border-box;
    display: block;
    margin: 0;
    padding: 0 30px;
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-family: $mainFontBold;
    font-size: $fontSizeRegular;
    color: $backgroundColorDark;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    background: transparent;
    outline: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    &:hover {
        text-decoration: none;
    }
}

button[disabled="disabled"] {
    text-decoration: none;
    cursor: auto;
}

/**
 * Button primary.
 */

.button-primary {
    width: auto;
    color: $textColorWhite;
    background: $highlightColor;
    border: 1px solid transparent;
    &.inverted {
        color: $highlightColor;
        background: $backgroundColorWhite;
        border-color: $highlightColor;
    }
    &:hover {
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
    }
    &:disabled {
        color: $highlightColorDark;
        cursor: auto;
        background: $backgroundColorBright;
        box-shadow: none;
    }
    i {
        float: left;
        margin-right: 10px;
        width: 14px;
        font-size: 13px;
    }
}

/**
 * Button secondary.
 */

.button-secondary {
    width: auto;
    color: $highlightColor;
    background: $backgroundColorWhite;
    border: 1px solid $highlightColor;
    &.inverted {
        color: $textColorWhite;
        background: transparent;
        border-color: $textColorWhite;
    }
    &:hover {
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
    }
    &:disabled {
        color: $textColorMain;
        cursor: auto;
        background: $backgroundColorWhite;
        border-color: $textColorMain;
        box-shadow: none;
    }
}

/**
 * Button back.
 */

.button-back {
    position: relative;
    padding: 0 15px 0 0;
    width: auto;
    height: 30px;
    line-height: 30px;
    font-size: $fontSizeNotSoTiny;
    text-align: left;
    text-indent: 33px;
    color: $highlightColor;
    background: $backgroundColorWhite;
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.25);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    &:before {
        content: '\e902';
        position: absolute;
        top: 1px;
        left: 10px;
        font-family: $iconFont;
        font-size: 12px;
        transform: rotate(180deg);
    }
    &:hover {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
    }
}

/**
 * Button download.
 */

.button-download {
    background: transparent;
    color: $textColorNotSoDark;
    font-size: $fontSizeRegular;
    font-family: $mainFont;
    text-align: center;
    padding: 0;
    position: relative;
    line-height: 104px;
    height: 65px;
    width: auto;
    overflow: hidden;
    &:hover {
        background: transparent;
        &:before {
            background: $backgroundColorShaded;
        }
    }
    &:before {
        background: $highlightColor;
        color: #FFF;
        content: '\e914';
        border-radius: 50%;
        height: 40px;
        width: 40px;
        line-height: 40px;
        font-family: "icomoon";
        font-size: 20px;
        position: absolute;
        left: calc(50% - 18px);
    }
    &.disabled {
        &:before {
            background: #ababaa;
        }
    }
}

/**
 * Button grey.
 */

.button-grey {
    font-family: $mainFont;
    color: $textColorDark;
    background: $backgroundColorMoreBright;
    &:hover {
        background: $backgroundColorMoreBright;
    }
}

/**
 * Button transparent.
 */

.button-transparent {
    display: flex;
    align-items: center;
    padding: 0;
    font-family: $mainFont;
    &:hover {
        background: transparent;
    }
    i {
        margin-right: 10px;
    }
}

/*------------------------------------*\
  #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
}

/*------------------------------------*\
  #MEDIA-QUERY-MEDIUM-REGULAR
\*------------------------------------*/

@include mediaQueryMediumRegular {
    .disclaimer-dialog-footer button {
        padding: 0 15px;
    }
}

/*------------------------------------*\
  #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    .button-download {
        font-size: $fontSizeBig;
        line-height: 125px;
        height: 75px;
    }
}