/*------------------------------------*\
    #MAIN-FOOTER
\*------------------------------------*/

main-footer {
    footer {
        margin-top: $paddingDimDesktop;
        width: 100%;
        background: $backgroundColorDark;
        font-family: $mainFontLight;


        /**
         * Main footer light area.
         */
        .light {
            box-sizing: border-box;
            text-align: center;
            color: $textColorWhite;
            background: $headerColor;
            padding: 30px 51px 50px 51px;
        }

        utilities {
            ul {
                align-items: flex-start;
            }
        }

        /**
         * Main footer dark area.
         */
        .dark {
            box-sizing: border-box;
            padding: 0 $borderDimMobile 50px 51px;
            html-text,
            section {
                padding: 0;
            }
        }
        a {
            color: $textColorWhite;
            font-size: $fontSizeRegular;
            line-height: 24px;

            &:hover {
                text-decoration: none;
            }
        }
        .legal {
            color: $textColorBright;
            display: block;
            font-size: $fontSizeRegular;
            line-height: 39px;
            margin: 20px 0 0 0;
        }
        /**
         * Main footer copyright.
         */
        .copyright, .phone-disclaimer {
            color: $textColorWhite;
            display: inline-block;
            margin-top: 30px;
            font-size: $fontSizeRegular;
            line-height: 24px;
            padding: 0 0 0 0;
            a {
                font-size: 16px;
                color: $textColorWhite;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        .phone-disclaimer {
            display: block;
            margin-top: 20px;
        }
    }
    /**
     * Page container.
     */
    .page-container {
        /**
         * Page container row.
         */
        &-row {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 0;
            /**
             * Page container cell.
             */
            &-cell {
                width: 100% !important;
                &:nth-child(1n+2) {
                    display: none;
                }
                h1 {
                    color: $textColorWhite;
                    font-size: $fontSizeNotSoBig;
                    font-family: $mainFontBold;
                    line-height: 25px;
                    margin: 0 0 15px 0;
                    text-align: left;
                }
                p {
                    font-size: $fontSizeRegular;
                    line-height: 24px;
                    color: $textColorWhite;
                    margin: 0;
                }

                a {
                    font-family: $mainFontLight;
                    color: $textColorWhite;
                    font-weight: 600;
                }

                .telephone {
                    font-weight: normal;
                }
            }
        }
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {
    main-footer {
        footer {
            .legal {
                display: none;
            }
            .copyright, .phone-disclaimer {
                /*margin-top: $paddingDimTablet;*/
                padding-bottom: 0;
            }
            .light,
            .dark {
                padding: $borderDimTablet;
            }

            .dark {
                padding-top: 0;
            }
        }
        /**
         * Page container.
         */
        .page-container {
            /**
             * Page container row.
             */
            &-row {
                /**
                 * Page container cell.
                 */
                &-cell {
                    box-sizing: border-box;
                    width: 50% !important;
                    padding-right: $borderDimTablet;
                    &:nth-child(1n+2) {
                        display: block;
                    }

                    &:nth-child(1n+3) {
                        margin-top: $borderDimTablet;
                    }
                }
            }
        }
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    main-footer {
        footer {
            margin-top: calc(#{$paddingDimDesktop} * 2);

            .light {
                padding: $borderDimDesktop $borderDimDesktop 70px $borderDimDesktop;
                height: $footerLightHeightRegular;
                position: relative;
                z-index: 1;
            }
            .dark {
                padding: 0 $borderDimDesktop calc(#{$borderDimDesktop / 2}) $borderDimDesktop;
                height: $footerDarkHeightRegular;
                position: relative;
                z-index: 1;
            }
            a {
                font-size: $fontSizeMiddle;
                line-height: 25px;
            }

            /**
             * Main footer copyright.
             */
            .copyright, .phone-disclaimer {
                font-size: $fontSizeMiddle;
                line-height: 25px;
                a {
                    font-size: $fontSizeMiddle;
                    color: $textColorWhite;
                }
            }
        }
        /**
         * Page container.
         */
        .page-container {
            /**
             * Page container row.
             */
            &-row {
                /**
                 * Page container cell.
                 */
                &-cell {
                    width: calc(100%/4) !important;
                    padding-right: calc(#{$borderDimDesktop}/2);
                    h1 {
                        margin-top: 0;
                        line-height: 29px;
                        font-size: $fontSizeBig;
                    }
                    p {
                        font-size: $fontSizeMiddle;
                        line-height: 25px;
                        color: $textColorWhite;
                        margin: 0;
                    }

                    &:nth-child(1n+3) {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}