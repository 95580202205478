/*------------------------------------*\
    #FIND-PRODUCTS
\*------------------------------------*/

.find-products {
    &.icon-loading {
        &:before {
            background: $backgroundColorWhite;
            height: 100%;
            top: 45px;
            left: 0;
            border-radius: 0;
            margin: 0;
            position: fixed;
        }
        &:after {
            top: 350px;
            position: fixed;
        }
    }
    section {
        tabs {
            tab {
                text-align: center;
                section[data-id] {
                    &.icon-loading {
                        &:before {
                            background: $backgroundColorWhite;
                            height: 100%;
                            width: 100%;
                            top: 45px;
                            left: 0;
                            border-radius: 0;
                            margin: 0;
                            position: fixed;
                        }
                        &:after {
                            top: 350px;
                            position: fixed;
                        }
                    }
                }
                .show-all {
                    display:none;
                }
            }
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/
@include mediaQueryMobile {
    tab section:first-child h3:first-child {
        margin-top: 0;
    }
}

@include mediaQueryMedium {
    .find-products {
        min-height: 200px;
        section {
            h2 {
                font-size: $fontSizeTall;
                line-height: 54px;
            }
            tabs {
                nav {
                    ul {
                        li {
                            flex-grow: 1;
                        }
                    }
                }
            }
        }
        flyout-documents {
            position: absolute;
            z-index: 10;
            top: 490px;
            right: 6px;
        }
    }
}

/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    .find-products section tabs tab:before{
        height: 0px;
    }
    .find-products {
        &.icon-loading {
            &:before {
                top: 245px;
                position: absolute;
                height: calc(100% - 245px);
            }
            &:after {
                top: 65vh;
                position: absolute;
            }
        }
        section {
            /**
             * Tabs.
             */
            tabs {
                display: block;
                margin-top: 80px;
                nav {
                    ul {
                        li {
                            flex-grow: 0;
                        }
                    }
                }
                tab {
                    &.icon-loading {
                        &:before {
                            background: $backgroundColorWhite;
                            top: 537px;
                            position: absolute;
                            height: calc(100% - 535px);
                            border-radius: 0;
                            margin: 0;
                            width: 100%;
                            left: 0;
                            z-index:1;
                        }
                        &:after {
                            top: 720px;
                            position: absolute;
                            z-index:1;
                        }
                    }
                    section[data-id] {
                        position: relative;
                        &.icon-loading {
                            &:before {
                                position: absolute;
                            }
                            &:after {
                                top: 50%;
                                position: absolute;
                            }
                        }
                    }
                    .show-all {
                        display: inline-block;
                        margin: 30px auto 0 auto;
                    }
                }
            }
        }
    }
}