/*------------------------------------*\
    #TEXT-PICTURE
\*------------------------------------*/

text-picture {
    @extend html-text;
    
    h2 {
        text-align: center;
    }
    
    img {
        display: block;
        
        &.left {
            margin: 0 auto 24px auto;
        }
        
        &.right {
            margin: 24px auto 0 auto;
        }
    }

    ul {
        list-style-type: disc;
    }
    ol {
        list-style-type: decimal;
    }
    ol, ul {
        padding-left: 20px;
    }
}


/*------------------------------------*\
    #MEDIA-QUERY-MEDIUM
\*------------------------------------*/

@include mediaQueryMedium {}


/*------------------------------------*\
    #MEDIA-QUERY-REGULAR
\*------------------------------------*/

@include mediaQueryRegular {
    text-picture {
        img {
            margin-top: 0;
            
            &.left {
                float: left;
                margin: 0 60px 24px -6.3vw;
            }
            
            &.right {
                float: right;
                margin: 0 -6.3vw 24px 60px;
            }
        }
    }
}